import React from "react";

export type InfoSection = {
  title?: string;
  content?: string | JSX.Element;
  image?: string;
  imageWidth?: string;
};

export type Lesson = {
  id: string;
  label: string;
  description: string;
  disabled?: boolean;
  info?: InfoSection[];
  video?: Array<{ video: string; cover?: string; text?: string | JSX.Element }>;
  audio?: Array<{ audio: string; text?: string | JSX.Element }>;
  videoCover?: string;
  file?: Array<{ file: string; name: string }>;
  homework?: boolean;
};

export type Block = {
  id: string;
  label: string;
  children: Lesson[];
  description: string;
  disabled?: boolean;
  speaker?: string;
};

export type TargetAudienceElement = {
  emoji: string;
  title: string;
  description: string;
  after: string;
  height: number;
};

export type AfterCourseBlockElement = {
  id: number;
  description: string;
};

export type Course = {
  id: string;
  label: string;
  children: Block[];
  targetAudience?: TargetAudienceElement[];
  afterCourseBlock?: AfterCourseBlockElement[];
};

export type AllCourses = Course[];

// for opening the modules
// const currentDate = new Date();
// const targetDateBlock4 = new Date("2023-08-18");
//targetDateBlock4 >= currentDate,

export const courses: AllCourses = [
  {
    id: "course1",
    label: "ГРУПОВЕ НАСТАВНИЦТВО",
    targetAudience: [
      {
        emoji: "../../assets/child.png",
        title: "Новачки",
        description:
          "Ви працюєте на роботі, яка вже вам давно не подобається, оплата маленька, ще й інколи працюєте по вихідним. Мрієте, що в один прекрасний момент зможете звільнитись без страху, жалю та без збитків у фінансовому стані. Хочете займатись улюбленою справою та жити життям мрії.",
        after:
          "Ви з нуля стали затребуваним таргетологом та працюєте в своє задоволення. Здійснюєте мрії та закриваєте свої «хотілки» самостійно. Працюєте з різних куточків світу маючи під рукою ноутбук та інтернет.",
        height: 261,
      },
      {
        emoji: "../../assets/woman-young.png",
        title: `Студенти`,
        description:
          "Ви закінчили школу, але так і не вирішили ким хочете працювати? Чи навчаєтесь в університеті, але зрозуміли, що не хочете працювати за освітою. А гроші хочеться мати зараз, щоб кайфово проводити свою молодість.",
        after:
          "Ти таргетолог, який працює вдень та заробляє від 1000$ в місяць. А ввечері проводиш час з друзями та витрачаєш кошти на емоції, адже зараз твій найкращий час у житті. Тож ти просто насолоджується тим, що відбувається навколо тебе.",
        height: 304,
      },
      {
        emoji: "../../assets/woman-technologist.png",
        title: `Діючі таргетологи та інші digital-спеціалісти`,
        description:
          "Ви вже пройшли курси, але до сих піл каша в голові. Немає чіткого плану, як рухатись, як шукати клієнтів та давати їм результати. Працюєте, але досі боїтесь вести соцмережи та будувати сильний особистий бренд. І ніяк не можете пробити свою фінансову стелю в заробітку.",
        after:
          "Вийшли на бажаний дохід в 3000$+ та працюєте тільки з тими проектами, які подобаються вам. Навчились казати «ні» та обирати себе. Ви впевнений спеціаліст з великим багажом знань та чітким планом, який допомагає вам рухатись вперед до цілей.",
        height: 253,
      },
      {
        emoji: "../../assets/woman-office-worker.png",
        title: "Власники бізнесу",
        description:
          "Ви будуєте ваш діючий бізнес або з нуля; хочете масштабуватись та збільшити дохід. Але ніяк не можете знайти свого таргетолога, або просто не хочете витрачати кошти на спеціаліста кожен місяць. Ви хочете самі зі всім розібратись, щоб просуватись так, як хочеться.",
        after:
          "Вже через 2 місяці ви почали самостійно ефективно просувати ваш бізнес. Збільшили дохід та впізнаваність бренду, впроваджуєте нові ідеї для просування та розумієте, що потрібно бізнесу далі для постійного росту.",
        height: 282,
      },
      {
        emoji: "../../assets/woman-feeding-baby.png",
        title: "Жінки в декреті",
        description:
          "Ви знаходитесь в декреті вже не перший місяць/рік, постійно одне й те саме, вже давно немає часу на себе та всі мрії відійшли на задній план. Набридло бути залежною від чоловіка та просити кошти на витрати для себе та дитини. Здається, що цей стан ніколи не закінчиться. Хочеться не сидіти, а робити щось корисне та отримувати задоволення.",
        after:
          "Ви почали працювати таргетологом, але достатньо приділяєте час роботі та родині, бо знаєте, як правильно його розділяти. Тримаєте комфортний для себе темп по кількості клієнтів та самі контролюєте свій дохід в залежності від можливостей та потреб. При цьому дитина росте у вас на очах і ви не пропускаєте найважливіші моменти материнства.",
        height: 239,
      },
    ],
    afterCourseBlock: [
      {
        id: 1,
        description:
          "Станете спеціалістом, який вмітиме запускати ефективну рекламу та давати клієнтам результат",
      },
      {
        id: 2,
        description: "Знайдете клієнтів та вийдете на стабільний дохід",
      },
      {
        id: 3,
        description:
          "Створете сильний особистий бренд, який відкриє нові можливості",
      },
      {
        id: 4,
        description:
          "Отримаєте знання з маркетингу, продажів та е-сом, щоб мати переваги серед конкурентів",
      },
      {
        id: 5,
        description: "Оточете себе сильним та крутим ком'юніті",
      },
    ],
    children: [
      {
        id: "block0",
        label: "Модуль 0. Вступ.",
        description:
          "Зрозумієш як взяти максимум від навчання та вийти після сильним спеціалістом",
        children: [
          {
            id: "block0-lesson1",
            label: "Урок 1. Методичка по користуванню платформою",
            description: "",
            info: [
              {
                content:
                  "Хоч ми і подбали про вас та зробили цілком зрозумілу та легку у використанні платформу, підготували для вас невеличку методичку по тому як користуватися навчальною платформою, аби не загубитися 🤗",
              },
              {
                title: "Особистий кабінет",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/Dev.%20svg%2FAvailable-courses.png?alt=media&token=5648457b-4409-4c66-9d6f-d02ca22076c8",
                imageWidth: "600",
              },
              {
                content:
                  "Після реєстрації особистого кабінету на навчальній платформі, ви одразу протрапите у свій кабінет, в якому буде весь список доступних для вас курсів.",
              },
              {
                content:
                  "Для того, щоб перейти до модулів та уроків, натисніть на кнопку “Перейти до уроків”.",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/Dev.%20svg%2FMenu.png?alt=media&token=e9696581-1cc2-4540-870c-d65c24793c44",
                imageWidth: "400",
              },
              {
                content:
                  "Повернутись до кабінету чи вийти з облікового запису, ви можете натиснути на свою іконку особистого кабінету та обрати дію “Кабінет” або “Вийти з акаунту”",
              },
              {
                title: "Модулі та уроки",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/Dev.%20svg%2FModules-and-lessons.png?alt=media&token=bb24c7ff-723c-4502-b29b-2b2cbfe0c002",
                imageWidth: "700",
              },
              {
                content: `Зліва знаходотиметься акивне меню доступних на даний момент модулів та уроків.
                Натискаючи на модуль, буде випливати меню доступних уроків.
                Ті модулі, які ще не доступні, будуть відкриватись поступово.`,
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/Dev.%20svg%2FLesson.png?alt=media&token=e954c36d-0e8c-4a25-8952-786a87380336",
                imageWidth: "700",
              },
              {
                content:
                  "У центральній, головній, частині знаходиться опис уроку, відео урок, додаткові важливі матеріали курсу.",
              },
              {
                title: "Завантаження домашнього завдання",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/Dev.%20svg%2FHomework.png?alt=media&token=d2f3182d-3ee6-404d-8f59-ce086d2400d6",
                imageWidth: "400",
              },
              {
                content:
                  "Для деяких уроків передбачені домашні завдання, для того, щоб ви отримували більше практики та щоб могли отримати зворотній відгук від наставника.",
              },
            ],
          },
          {
            id: "block0-lesson2",
            label:
              "Урок 2. Як взяти максимум від навчання та вийти після сильним спеціалістом",
            description: "",
            info: [
              {
                title: "Почніть з питання «НАВІЩО» та поставте цілі",
                content: `
                  Важливо, коли ми починаємо, щось нове, ми маємо розуміти куди ми хочемо прийти в результаті. Тому сформуйте чіткий запит, щоб рухатись до мети було значно легше та швидше. І не забувайте, час від часу повертатись та перечитувати вашу істину мету, щоб не губити її серед можливого нав‘язування суспільством.
                `,
              },
              {
                title:
                  "Звільніть собі час для навчання та створіть собі атмосферу для навчання",
                content: `
                  Щоб матеріал сприймався легше та зрозуміліше - зробіть собі робоче місце, де нічого і ніхто не буде вас відволікати. Виділяйте вдень необхідну кількість годин, щоб проходити навчальний матеріал та виконувати всі завдання. Не рекомендую сидіти днями та ночами, бо ви будете втомлюватись та інформація просто не буде засвоюватися.
                  Рекомендую також завести окремий блокнот/зошит, щоб вести конспет з всією необхідною інформацією. Таким чином ви швидко зможете нагадувати собі важливі моменти без повернення до перегляду повного уроку.
                `,
              },
              {
                title: "Не бійтесь задавати питання в чат",
                content: `
                  Ви маєте особистий чат потоку та загальний з усіма учнями, тому питання ви можете писати всюди. Не бійтесь, адже всі ми починаємо однаково. Тим більше, таким чином ви будете економити свій час - ви швидше можете отримати допомогу через відповідь інших, ніж самостійно будете робити помилки для вирішення будь-якої проблеми.
                  Також ви завжди можете написати мені особисто, якщо це необхідно 🤍
                  У нас дуже дружня атмосфера, тому не бійтесь питати та допомагати один одному
                `,
              },
              {
                title:
                  "Після проходження навчання поверніться до уроків ще раз",
                content: `
                Дуже часто буває, що передивляючись уроки - ми можемо почути те, на що перший раз не звернули увагу. Та навіть можуть приходити нові ідеї для покращення своєї роботи. Тож час від часу повертайтесь до навчального матеріалу для закріплення.
                `,
              },
              {
                title:
                  "Максимально переймайте досвід і знання свого наставника",
                content: `
                Не бійтесь розпитувати про реалізацію моїх проектів, етапи кар'єри, успіхи і невдачі, причини, що вплинули на кінцевий результат та ін. Проявляйте ініціативу і зацікавленість. Є висока ймовірність того, що в подальшому ці знання стануть найціннішим вашим активом, а я в свою чергу дам вам хороші рекомендації
                `,
              },
              {
                title:
                  "Розширюйте коло спілкування і заводьте нові знайомства в професійній сфері",
                content: `
                Ваші сьогоднішні товариші по навчанню завтра можуть стати колегами і партнерами по проектам. А може ще й кращими друзями 💙 тому будьте активні, в digital-сфері дуже багато крутих людей.
Процес навчання не закінчується отриманням сертифікату, як і не закінчується, коли нам виповнюється 30, 40, 60 років. Ми живемо в світі, де зміни відбуваються й інновації народжуються з неймовірною швидкістю. Тому кожному фахівцю, який хоче бути успішним у своїй сфері, рекомендую взяти на озброєння принцип навчання протягом усього життя! 
                `,
              },
              {
                title: "Практика наше все! ",
                content: `
                Тому черпайте теоретичний матеріал та старайтесь одразу використовувати все на практиці. Пам‘ятайте, що на реалізацію ідеї достатньо 48 годин, тому не відкладайте в довгий ящик, якщо хочете мати успіх найближчим часом
                `,
              },
            ],
          },
          {
            id: "block0-lesson3",
            label: "Урок 3. Домашнє завдання",
            description: "",
            info: [
              {
                title:
                  "Це завдання ВАЖЛИВО виконати чесно перед самою/самим собою, тому що це буде ваш вектор на найближчий час. І від ваших цілей залежить, які дії треба буде робити для їх здійснення ",
                content: `
            Чітко сформувати вашу точку А (розписати в якому стані ви знаходитесь зараз, що відчуваєте, які сумніви/страхи є, який маєте дохід, що відбувається в житті і тд.) 
та чітко прописати точку Б (якою/яким ви себе бачите через 2-3 місяці, що хочете мати, в якому хочете бути стані, до яких результатів та способі життя прийти і тд.)


            `,
              },
            ],
            homework: true,
          },
        ],
      },
      {
        id: "block1",
        label: "Модуль 1. Сесія з психологом",
        disabled: false,
        speaker: "Запрошенний спікер Анастасія Янченко",
        description:
          "Ти зможеш позбутися своїх внутрішніх, які не давали почати змінювати своє життя",
        children: [
          {
            id: "block1-lesson1",
            label: "Урок 1. Сесія з психологом",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                title:
                  "• Чому наші страхи забирають в нас життя мрії та як з цим боротись",
              },
              {
                title:
                  "• Методи еффективної роботи. Як встигати все в роботі та жити життя",
              },
              {
                title:
                  "• Мотивація та енергії, які дають можливість завжди рухатись вперед",
              },
              {
                title: "• Вихід із зони комфорту",
              },
              {
                content:
                  "та багато чого іншого ви дізнаєтесь від спікера Анастасії Янченко (психолог)",
              },
              {
                title: "Вже після: ",
                content:
                  "Ви зможете позбутися своїх внутрішніх страхів, які не давали почати змінювати своє життя!",
              },
            ],
          },
          {
            id: "block1-lesson2",
            label: "Урок 2. Запис ZOOM-зідзвону",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%201%2F%D0%B7%D1%83%D0%BC%20%D0%B7%20%D0%BF%D1%81%D0%B8%D1%85%D0%BE%D0%BB%D0%BE%D0%B3%D0%BE%D0%BC.mp4?alt=media&token=32d77692-9a21-4406-990e-86597b7a7fc0",
              },
            ],
            videoCover: "",
            info: [{ content: "" }],
          },
        ],
      },

      {
        id: "block2",
        label: "Модуль 2. База в таргетованій рекламі",
        description:
          "Ти дізнаєшся всі можливі методи пошуку клієнтів, їх закриття та як з ними працюват - що просто НЕРЕАЛЬНО буде піти з навчання без клієнтів",
        disabled: false,
        children: [
          {
            id: "block2-lesson1",
            label: "Урок 1. База в таргетованій рекламі",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content:
                  "Ви познайомитесь з початковою частинок роботи таргетолога та зрозумієте, що до чого та як все працює",
              },
            ],
          },
          {
            id: "block2-lesson2",
            label: "Урок 2. Що таке таргет?",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F2%20lesson%20-%20%D1%89%D0%BE%20%D1%82%D0%B0%D0%BA%D0%B5%20%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82%3F%2F%D1%89%D0%BE%20%D1%82%D0%B0%D0%BA%D0%B5%20%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82.MP4?alt=media&token=0eae768e-61fb-4264-b3fe-8b14ba59d799",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F2%20lesson%20-%20%D1%89%D0%BE%20%D1%82%D0%B0%D0%BA%D0%B5%20%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82%3F%2F%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82%D0%BE%D0%BB%D0%BE%D0%B3.png?alt=media&token=42969d5a-aa69-4375-832d-5efd017af94a",
              },
            ],

            disabled: false,
            info: [
              {
                title:
                  "Що таке таргет? Що робить таргетолог? Як все працює та чому в тебе все точно вийде",
              },
            ],
          },
          {
            id: "block2-lesson3",
            label: "Урок 3. Як підготувати сторінку до реклами",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F3%20lesson%20-%20%D1%8F%D0%BA%20%D0%BF%D1%96%D0%B4%D0%B3%D0%BE%D1%82%D1%83%D0%B2%D0%B0%D1%82%D0%B8%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D1%83%20%D0%B4%D0%BE%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%B8%2F%D1%8F%D0%BA%20%D0%BF%D1%96%D0%B4%D0%B3%D0%BE%D1%82%D1%83%D0%B2%D0%B0%D1%82%D0%B8%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D1%83%20%D0%B4%D0%BE%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%B8.MP4?alt=media&token=0ebdf107-1421-4f6f-a623-4e255db44514",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F3%20lesson%20-%20%D1%8F%D0%BA%20%D0%BF%D1%96%D0%B4%D0%B3%D0%BE%D1%82%D1%83%D0%B2%D0%B0%D1%82%D0%B8%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D1%83%20%D0%B4%D0%BE%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%B8%2F%D1%8F%D0%BA%20%D0%BF%D1%96%D0%B4%D0%B3%D0%BE%D1%82%D1%83%D0%B2%D0%B0%D1%82%D0%B8.png?alt=media&token=2741add8-70ff-48c3-97c0-cdbb444a16cf",
              },
            ],
            disabled: false,
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F3%20lesson%20-%20%D1%8F%D0%BA%20%D0%BF%D1%96%D0%B4%D0%B3%D0%BE%D1%82%D1%83%D0%B2%D0%B0%D1%82%D0%B8%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D1%83%20%D0%B4%D0%BE%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%B8%2F%D0%AF%D0%BA%20%D0%BF%D1%96%D0%B4%D0%B3%D0%BE%D1%82%D1%83%D0%B2%D0%B0%D1%82%D0%B8%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D1%83%20%D0%B4%D0%BE%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%B8.pdf?alt=media&token=0e56faa0-b2db-4007-83ea-cb1baf912fb6",
                name: "Як підготувати сторінку до реклами",
              },
            ],
            info: [
              {
                content: "",
              },
            ],
          },
          {
            id: "block2-lesson4",
            label: "Урок 4. Термінологія та сервіси для роботи",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                title: "Обов'язковий матеріал для вивчення!",
                content:
                  "Термінологія - це те, з чого треба починати, аби далі ви розуміли робочий сленг. Тому цей файл ви не скачали та забули, а саме перечитуєте та постійно вивчаєте в процесі роботи.",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F4%20%D1%83%D1%80%D0%BE%D0%BA%20-%20%D1%82%D0%B5%D1%80%D0%BC%D1%96%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%8F%20%D1%82%D0%B0%20%D1%81%D0%B5%D1%80%D0%B2%D1%96%D1%81%D0%B8%20%D0%B4%D0%BB%D1%8F%20%D1%80%D0%BE%D0%B1%D0%BE%D1%82%D0%B8%2F%D0%A2%D0%B5%D1%80%D0%BC%D1%96%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%8F.pdf?alt=media&token=910d411b-b8dc-47c3-803e-4f99ab59fe80",
                name: "Термінологія",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F4%20%D1%83%D1%80%D0%BE%D0%BA%20-%20%D1%82%D0%B5%D1%80%D0%BC%D1%96%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%8F%20%D1%82%D0%B0%20%D1%81%D0%B5%D1%80%D0%B2%D1%96%D1%81%D0%B8%20%D0%B4%D0%BB%D1%8F%20%D1%80%D0%BE%D0%B1%D0%BE%D1%82%D0%B8%2F%D0%9A%D0%BE%D1%80%D0%B8%D1%81%D0%BD%D1%96%20%D1%81%D0%B5%D1%80%D0%B2%D1%96%D1%81%D0%B8%20%D0%B4%D0%BB%D1%8F%20%D1%80%D0%BE%D0%B1%D0%BE%D1%82%D0%B8.pdf?alt=media&token=b60ce427-6256-4961-b705-65fed9d1d8f5",
                name: "Корисні сервіси для роботи",
              },
            ],
          },
          {
            id: "block2-lesson5",
            label: "Урок 5. Зони відповідальності таргетолога та клієнта",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F5%20lesson%20-%20%D0%B7%D0%BE%D0%BD%D0%B8%20%D0%B2%D1%96%D0%B4%D0%BF%D0%BE%D0%B2%D1%96%D0%B4%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%96%20%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82%D0%BE%D0%BB%D0%BE%D0%B3%D0%B0%20%D1%82%D0%B0%20%D0%BA%D0%BB%D1%96%D1%94%D0%BD%D1%82%D0%B0%2F%D0%B7%D0%BE%D0%BD%D0%B8%20%D0%B2%D1%96%D0%B4%D0%BF%D0%BE%D0%B2%D1%96%D0%B4%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%96.MP4?alt=media&token=08c0ff25-3893-47bc-be51-2dfe1ef313e5",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F5%20lesson%20-%20%D0%B7%D0%BE%D0%BD%D0%B8%20%D0%B2%D1%96%D0%B4%D0%BF%D0%BE%D0%B2%D1%96%D0%B4%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%96%20%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82%D0%BE%D0%BB%D0%BE%D0%B3%D0%B0%20%D1%82%D0%B0%20%D0%BA%D0%BB%D1%96%D1%94%D0%BD%D1%82%D0%B0%2F%D0%B7%D0%BE%D0%BD%D0%B8.png?alt=media&token=c2ada902-e268-4b31-90a0-dc1dccae1863",
              },
            ],
            disabled: false,
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F5%20lesson%20-%20%D0%B7%D0%BE%D0%BD%D0%B8%20%D0%B2%D1%96%D0%B4%D0%BF%D0%BE%D0%B2%D1%96%D0%B4%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%96%20%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82%D0%BE%D0%BB%D0%BE%D0%B3%D0%B0%20%D1%82%D0%B0%20%D0%BA%D0%BB%D1%96%D1%94%D0%BD%D1%82%D0%B0%2F%D0%97%D0%BE%D0%BD%D0%B8%20%D0%B2%D1%96%D0%B4%D0%BF%D0%BE%D0%B2%D1%96%D0%B4%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%96.pdf?alt=media&token=f423b674-129a-4540-910d-55dcec4c4173",
                name: "Зони відповідальності таргетолога та клієнта",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D1%96%D1%80%20%D0%BF%D1%80%D0%BE%20%D0%BD%D0%B0%D0%B4%D0%B0%D0%BD%D0%BD%D1%8F%20%D0%BF%D0%BE%D1%81%D0%BB%D1%83%D0%B3%20%D0%B7%20%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82%D0%BE%D0%B2%D0%B0%D0%BD%D0%BE%D1%97%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%B8%20.docx?alt=media&token=42e7dfb3-637c-4fe6-b5c6-94ef476c7eee",
                name: "Договір про надання послуг з таргетованої реклами",
              },
            ],
            info: [
              {
                content: "",
              },
            ],
          },
          {
            id: "block2-lesson6",
            label: "Урок 6. Робочі сторінки для роботи з рекламою. Доступи",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F6%20lesson%20-%20%D1%80%D0%BE%D0%B1%D0%BE%D1%87%D1%96%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D0%B8.%D0%94%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B8%2F%D1%80%D0%BE%D0%B1%D0%BE%D1%87%D1%96%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D0%B8.mov?alt=media&token=a614ff8d-ed4d-4220-90c0-a8b550c079fe",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F6%20lesson%20-%20%D1%80%D0%BE%D0%B1%D0%BE%D1%87%D1%96%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D0%B8.%D0%94%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B8%2F%D1%80%D0%BE%D0%B1%D0%BE%D1%87%D1%96.png?alt=media&token=53283651-65d5-41b6-841f-47ce1db6ac50",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F6%20lesson%20-%20%D1%80%D0%BE%D0%B1%D0%BE%D1%87%D1%96%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D0%B8.%D0%94%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B8%2F%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%20%D0%B4%D0%BE%20%D0%B1%D0%BC.MP4?alt=media&token=7007177d-d1b7-4353-924d-8c250968f594",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F6%20lesson%20-%20%D1%80%D0%BE%D0%B1%D0%BE%D1%87%D1%96%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D0%B8.%D0%94%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B8%2F%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%20%D0%91%D0%9C.png?alt=media&token=6ea2aca6-9ce1-4fa5-9e66-057fd6b5ef09",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F6%20lesson%20-%20%D1%80%D0%BE%D0%B1%D0%BE%D1%87%D1%96%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D0%B8.%D0%94%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B8%2F%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%20%D0%B4%D0%BE%20%D0%B1%D1%81.MP4?alt=media&token=7ba3e34a-d865-4d38-a38e-ece5bc90e9c1",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F6%20lesson%20-%20%D1%80%D0%BE%D0%B1%D0%BE%D1%87%D1%96%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D0%B8.%D0%94%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B8%2F%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%20%D0%91%D0%A1.png?alt=media&token=c07c2b2c-d112-437f-8f03-53b38cd9fd4a",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F6%20lesson%20-%20%D1%80%D0%BE%D0%B1%D0%BE%D1%87%D1%96%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D0%B8.%D0%94%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B8%2F%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%20%D0%B4%D0%BE%20%D1%80%D0%BA.MP4?alt=media&token=d957b10e-5cde-4bb4-bbab-04f287db1d37",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F6%20lesson%20-%20%D1%80%D0%BE%D0%B1%D0%BE%D1%87%D1%96%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D0%BD%D0%BA%D0%B8.%D0%94%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B8%2F%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%20%D0%A0%D0%9A.png?alt=media&token=64c2f016-f101-4d4d-9430-720672afabc7",
              },
            ],
            disabled: false,
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D0%9F%D1%96%D0%B4%D1%82%D0%B2%D0%B5%D1%80%D0%B4%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F%20%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D1%81%D1%82%D0%BE%D1%81%D1%82%D1%96%20Facebook%20%D0%B4%D0%BB%D1%8F%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%BA%D0%B0%D0%B1%D1%96%D0%BD%D0%B5%D1%82%D1%83.pdf?alt=media&token=e9f3431a-72db-412b-8a55-95699d91e55c",
                name: "Підтвердження особистості Facebook для рекламного кабінету",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D0%A1%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B0%20%D0%B7%D0%B2%CA%BC%D0%B7%D0%BA%D1%96%D0%B2%20Facebook%20%D1%82%D0%B0%20%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%BD%D1%96%20%D1%80%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D0%B0%D1%86%D1%96%D1%97.pdf?alt=media&token=4f6a830d-0b6d-4e50-8ee6-8338cfa114eb",
                name: "Система звʼзків Facebook та основні рекомендації",
              },
            ],
            info: [
              {
                content: "",
              },
            ],
          },
          {
            id: "block2-lesson7",
            label: "Урок 7. Д/З",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                title: "Домашнє завдання",
                content:
                  "Підготувати/створити свої робочі сторінки в Facebook для подальшої роботи",
              },
            ],
            homework: true,
          },
          {
            id: "block2-lesson8",
            label: "Урок 8. Створення БІЗНЕС МЕНЕДЖЕРУ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F8%20lesson%20-%20%D1%81%D1%82%D0%B2%D0%BE%D1%80%D0%B5%D0%BD%D0%BD%D1%8F%20%D0%91%D0%9C%2F%D1%81%D1%82%D0%B2%D0%BE%D1%80%D0%B5%D0%BD%D0%BD%D1%8F%20%D0%B1%D0%BC.MP4?alt=media&token=6132df52-a6d5-457b-b45c-edd83be39726",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F8%20lesson%20-%20%D1%81%D1%82%D0%B2%D0%BE%D1%80%D0%B5%D0%BD%D0%BD%D1%8F%20%D0%91%D0%9C%2F%D1%81%D1%82%D0%B2%D0%BE%D1%80%D0%B5%D0%BD%D0%BD%D1%8F%20%D0%91%D0%9C.png?alt=media&token=9bcf028b-fc38-423f-8ecd-b6a8f44d7b70",
              },
            ],
            disabled: false,
            info: [
              {
                content: "",
              },
            ],
            file: [
              {
                file: "https://business.facebook.com/overview",
                name: "Створення бізнес менеджеру",
              },
            ],
          },
          {
            id: "block2-lesson9",
            label: "Урок 9. Брифування клієнтів",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F9%20lesson%20-%20%D0%B1%D1%80%D0%B8%D1%84%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D0%BA%D0%BB%D1%96%D1%94%D0%BD%D1%82%D1%96%D0%B2%2F%D0%B1%D1%80%D0%B8%D1%84%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D0%BA%D0%BB%D1%96%D1%94%D0%BD%D1%82%D1%96%D0%B2.MP4?alt=media&token=d1cc910d-d23a-4750-bcca-fdbeb53f7ab0",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F9%20lesson%20-%20%D0%B1%D1%80%D0%B8%D1%84%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D0%BA%D0%BB%D1%96%D1%94%D0%BD%D1%82%D1%96%D0%B2%2F%D0%B1%D1%80%D0%B8%D1%84%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F.png?alt=media&token=0ff07f2a-a5cf-4a9d-9daf-cb8134657080",
              },
            ],
            disabled: false,
            info: [
              {
                content: "",
              },
            ],
            file: [
              {
                file: "https://forms.gle/VNweSsA9rgjbCjERA",
                name: "Приклад брифування клієнтів",
              },
            ],
          },
          {
            id: "block2-lesson10",
            label: "Урок 10. Д/З",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                title: "Домашнє завдання",
                content:
                  "Створити свій бриф для подальшої роботи з клієнтами (не важливо як саме він буде виглядати візуально, ГОЛОВНЕ, щоб він був доступний для клієнтів та допомагав у підготовці проекту вам)",
              },
            ],
            homework: true,
          },
          {
            id: "block2-lesson11",
            label: "Урок 11. Аналіз проекту та конкурентів",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F11%20lesson%20-%20%D0%B0%D0%BD%D0%B0%D0%BB%D1%96%D0%B7%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D1%83%20%D1%82%D0%B0%20%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D0%B5%D0%BD%D1%82%D1%96%D0%B2%2F%D0%B0%D0%BD%D0%B0%D0%BB%D1%96%D0%B7%20%D0%BD%D1%96%D1%88%D1%96%20%D1%82%D0%B0%20%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D0%B5%D0%BD%D1%82%D1%96%D0%B2.MP4?alt=media&token=44937e5a-4b0f-48ec-b656-4a36e10ed185",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F11%20lesson%20-%20%D0%B0%D0%BD%D0%B0%D0%BB%D1%96%D0%B7%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D1%83%20%D1%82%D0%B0%20%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D0%B5%D0%BD%D1%82%D1%96%D0%B2%2F%D0%B0%D0%BD%D0%B0%D0%BB%D1%96%D0%B7.png?alt=media&token=9ba378a9-d8b3-4214-824c-249075a60632",
              },
            ],
            disabled: false,
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F11%20lesson%20-%20%D0%B0%D0%BD%D0%B0%D0%BB%D1%96%D0%B7%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D1%83%20%D1%82%D0%B0%20%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D0%B5%D0%BD%D1%82%D1%96%D0%B2%2F%D0%90%D0%BD%D0%B0%D0%BB%D1%96%D0%B7%20%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D0%B5%D0%BD%D1%82%D1%96%D0%B2.pdf?alt=media&token=5ba5b838-fa12-4b32-a487-9e4286993be8",
                name: "Аналіз проекту та конкурентів",
              },
            ],
            info: [
              {
                content: "",
              },
            ],
          },
          {
            id: "block2-lesson12",
            label: "Урок 12. Як розрахувати рекламний бюджет для проекту",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F12%20lesson%20-%20%D1%8F%D0%BA%20%D1%80%D0%BE%D0%B7%D1%80%D0%B0%D1%85%D1%83%D0%B2%D0%B0%D1%82%D0%B8%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%BD%D0%B8%D0%B9%20%D0%B1%D1%8E%D0%B4%D0%B6%D0%B5%D1%82%20%D0%B4%D0%BB%D1%8F%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D1%83%2F%D0%AF%D0%BA%20%D1%80%D0%BE%D0%B7%D1%80%D0%B0%D1%85%D1%83%D0%B2%D0%B0%D1%82%D0%B8%20%D0%B1%D1%8E%D0%B4%D0%B6%D0%B5%D1%82%20%D0%BD%D0%B0%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D1%83.pdf?alt=media&token=2552a204-0959-4114-b6da-c235b7f7dd0c",
                name: "Як розрахувати рекламний бюджет для проекту",
              },
            ],
            info: [
              {
                content: "",
              },
            ],
          },
          {
            id: "block2-lesson13",
            label: "Урок 13. Запис ZOOM-зідзвону",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%202%2F13%20lesson%20-%20%D0%B7%D1%83%D0%BC%20%D0%B7%D1%96%D0%B4%D0%B7%D0%B2%D0%BE%D0%BD%2Fvideo1501404537.mp4?alt=media&token=d01d136a-6e55-4402-941c-69fc569dffd7",
              },
            ],
          },
        ],
      },
      {
        id: "block3",
        label: "Модуль 3. Цільова аудиторія",
        description:
          "Ти навчишся виявляти ЦА для своїх клієнтів, яка буде готова купувати, а не залишати “пусті заявки”",
        disabled: false,
        children: [
          {
            id: "block3-lesson1",
            label: "Урок 1. ЦІЛЬОВА АУДИТОРІЯ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: `Ти навчишся виявляти ЦА для своїх клієнтів, яка буде готова купувати, а не залишати "пусті заявки" та правильно використовувати це в роботі.`,
              },
            ],
          },
          {
            id: "block3-lesson2",
            label: "Урок 2. Хто така ЦА? ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: "",
              },
            ],
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2F%D1%85%D1%82%D0%BE%20%D1%82%D0%B0%D0%BA%D0%B0%20%D1%86%D0%B0.MP4?alt=media&token=46ca9ea3-752e-4bb8-bccf-348a1e19590b",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2Fcovers%2F%D1%85%D1%82%D0%BE%20%D1%82%D0%B0%D0%BA%D0%B0%20%D1%86%D0%B0.jpg?alt=media&token=529b32c9-fb9b-415f-932a-bdc457c08043",
              },
            ],
          },
          {
            id: "block3-lesson3",
            label: "Урок 3. Аналіз ЦА бізнесу",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: "",
              },
            ],
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2F%D0%B0%D0%BD%D0%B0%D0%BB%D1%96%D0%B7%20%D1%86%D0%B0.MP4?alt=media&token=9d0cf7e3-909a-4770-a00e-a9bcb0353e85",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2Fcovers%2F%D0%B0%D0%BD%D0%B0%D0%BB%D1%96%D0%B7%20%D1%86%D0%B0%20%D0%B1%D1%96%D0%B7%D0%BD%D0%B5%D1%81%D1%83.jpg?alt=media&token=7ceb56b3-c763-4240-9c17-58025ab01bda",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2F%D0%90%D0%BD%D0%B0%D0%BB%D1%96%D0%B7%20%D1%86%D1%96%D0%BB%D1%8C%D0%BE%D0%B2%D0%BE%D1%96%CC%88%20%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D1%96%D1%96%CC%88.pdf?alt=media&token=fb9ea82a-be51-4f16-a085-380e159fafab",
                name: "Аналіз цільової аудиторії",
              },
            ],
          },
          {
            id: "block3-lesson4",
            label: "Урок 4. Розбір таблиці для ЦА на прикладі ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            file: [
              {
                file: "https://docs.google.com/spreadsheets/d/1h-kc21PcrDMyUKqya7yv1FOXaywFuqhrDVhBSO5zi_o/edit?usp=sharing",
                name: "Таблиця для аналізу ЦА",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D0%A6%D0%90%20BAZA%20Hotel.pdf?alt=media&token=b8754a89-71f1-4297-b2a4-1e8f8d24e6ae",
                name: "Як використовувати данні з таблиці при створені та роботі з цільовою аудиторією клієнта",
              },
            ],
            info: [
              {
                title:
                  "ВАЖЛИВО! Заповнювати її з реальних відповідей вашої аудиторії. Тоді результат аналізу буде якісний.",
                content: `
                  Для прикладу, таблиця заповнена по сегментам та відповідям на питання. Ви можете скопіювати собі та користуватись повною версією, або ж після копії внести свої правки та використовувати в роботі`,
              },
            ],
          },
          {
            id: "block3-lesson5",
            label: "Урок 5. Методи сегментації аудиторії",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: "",
              },
            ],
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2F%D1%81%D0%B5%D0%B3%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D1%96%D1%8F%20%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D1%96%D1%96%CC%88.MP4?alt=media&token=a1c2191a-94f2-4b09-a7e5-ef71448c14cf",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2Fcovers%2F%D0%BC%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%20%D1%81%D0%B5%D0%B3%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D1%96%D1%97.jpg?alt=media&token=0b2dc184-f57e-481e-b0e1-78ab2723b042",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2F%D0%B2%D0%B8%D0%B4%D0%B8%20%D1%96%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%96%D0%B2.MP4?alt=media&token=55f539cf-74ca-4957-8010-749825b5c5c8",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2F%D0%A1%D0%B5%D0%B3%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D1%96%D1%8F.pdf?alt=media&token=c0f47f39-553b-4436-a9de-6bbb49e2ca98",
                name: "Сегментація",
              },
            ],
          },
          {
            id: "block3-lesson6",
            label:
              "Урок 6. Як створити та працювати з ретаргетом та lal аудиторіями",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: "",
              },
            ],
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2F%D1%80%D0%B5%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82.MP4?alt=media&token=b06e018a-041c-4e0f-b8c9-81490a8ea290",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2Fcovers%2F%D1%81%D1%82%D0%B2%D0%BE%D1%80%D0%B5%D0%BD%D0%BD%D1%8F%20%D1%80%D0%B5%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82%20%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D1%96%D1%97.jpg?alt=media&token=506a9829-4f8a-4991-836d-21ac203f5b1e",
              },
            ],
          },
          {
            id: "block3-lesson7",
            label: "Урок 7. Звуження аудиторії",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2F%D0%B7%D0%B2%D1%83%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F%20%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D1%96%D1%96%CC%88.MP4?alt=media&token=28c74eba-aa93-487d-9c79-7ba788351110",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2Fcovers%2F%D0%B7%D0%B2%D1%83%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F%20%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D1%96%D1%97.jpg?alt=media&token=78118366-6120-4210-ba1c-678054afa41f",
              },
            ],
            info: [
              {
                content: `Якщо ви додасте критерії в поле "Включити людей" або "Виключити людей", до вашої аудиторії будуть включені (або з неї будуть виключені) люди, які відповідають хоча б одному з критеріїв. Допустимо, ви включили в аудиторію людей, які:`,
              },
              {
                content: "- часто мандрують;",
              },
              {
                content: " - цікавляться кулінарією;",
              },
              {
                content: "- мають вищу освіту.",
              },
              {
                content: `Людина, яка цікавиться кулінарією, але не часто подорожує та/або не має вищої освіти, увійде до вашої аудиторії. Це націлення "або", оскільки аудиторія складатиметься з людей, які часто подорожують чи цікавляться кулінарією чи закінчили вищий навчальний заклад.`,
              },
              {
                content:
                  "Якщо ви хочете включити або виключити лише людей, які відповідають усім критеріям, можете звузити аудиторію. Як це зробити, дивіться в уроці:",
              },
              {
                title: `Примітка. У вас може бути кілька рівнів "Звузити ще сильніше", які пов'язані між собою так само, як рівні "Включити/Виключити" та "Звузити аудиторію" (у них будуть однакові назви).`,
              },
              {
                content: `Ви можете додати вегетаріанців на рівні "Включити/Виключити" та людей, які цікавляться садівництвом, на рівні "Звузити аудиторію". Ось що у вас вийде:`,
              },
              {
                content: `- Увімкнути/Виключити: люди, які часто подорожують, або вегетаріанці`,
              },
              {
                content: `- Звузити аудиторію: люди, які цікавляться кулінарією чи садівництвом`,
              },
              {
                content: `- Звузити ще сильніше: люди, які здобули вищу освіту`,
              },
              {
                content:
                  "Для включення/виключення необхідно дотримання одного критерію кожного рівня. Наприклад, вегетаріанець, який цікавиться садівництвом та здобув вищу освіту, потрапить у вашу аудиторію, але людина, яка часто подорожує та цікавиться кулінарією, але не має вищої освіти, до неї не потрапить.",
              },
              {
                title: `Важливо! Коли ви звужуєте аудиторію, спробуйте не робити її надто вузькою. Це може призвести до того, що ваша аудиторія виявиться надто маленькою та неефективною. Ми автоматично намагаємося вибрати з цільової аудиторії людей, які з найбільшою ймовірністю дадуть результат, для якого ви налаштували оптимізацію при створенні групи оголошень. Тому немає потреби значно звужувати аудиторію. При створенні аудиторії можна переглянути її прогнозований розмір. Якщо аудиторія занадто вузька або широка, налаштуйте параметри націлювання.
                `,
              },
              {
                content: "(* з довідника Facebook)",
              },
            ],
          },
          {
            id: "block3-lesson8",
            label: "Урок 8. Перетин аудиторії",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content:
                  "Перетин аудиторій – це не завжди погано, але це може негативно впливати на показ груп об'яв. Якщо групи оголошень одного рекламодавця виявляються на одному аукціоні (оскільки таргетовані на схожі аудиторії), ми обираємо групу, яка найчастіше показувала найвищу результативність, і не допускаємо до показу інші. Це робиться для того, щоб ваші оголошення не конкурували. Така конкуренція може призвести до підвищення витрат та неефективного витрачання бюджету. Ви можете перевірити аудиторії на перетин за допомогою спеціального інструменту. ",
              },
              {
                content: "Для цього дивіться урок:",
              },
              {
                content: "(* з довідника Facebook)",
              },
            ],
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2F%D0%BF%D0%B5%D1%80%D0%B5%D1%82%D0%B8%D0%BD%20%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D1%96%D1%96%CC%88.MP4?alt=media&token=44dff3a3-0d22-440e-af54-91c3333efae3",
                cover:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2Fcovers%2F%D0%BF%D0%B5%D1%80%D0%B5%D1%82%D0%B8%D0%BD%20%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D1%96%D1%97.jpg?alt=media&token=11ebeccc-1fd3-42a1-9be3-ed89642bfab3",
              },
            ],
          },
          {
            id: "block3-lesson9",
            label: "Урок 9. ДЗ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content:
                  "Провести аналіз аудиторії у форматі інтерв’ю для себе (та якщо є проекти - для своїх клієнтів), заповнити цю інформацію в таблицю та вислати на перевірку",
              },
            ],
            homework: true,
          },
          {
            id: "block3-lesson10",
            label: "Урок 10. Запис зум-зідзвону",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%203%2F2%20%D0%B7%D1%83%D0%BC-%D0%B7%D1%83%D1%81%D1%82%D1%80%D1%96%D1%87%20.mp4?alt=media&token=7e053161-2e3b-407f-bf50-31c94a5299f9",
              },
            ],
          },
        ],
      },
      {
        id: "block4",
        label: "Модуль 4. Технічна робота з ADS Manager",
        description:
          "Після даного модулю ти отримаєш широкі знання по технічній частині, налаштуванні реклами і трошки секретів, про які мало хто знає",
        disabled: false,
        children: [
          {
            id: "block4-lesson1",
            label: "Урок 1. Технічна робота з ADS Manager",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            info: [
              {
                content:
                  "Після даного модулю ти отримаєш широкі знання по технічній частині, налаштуванні реклами і трошки секретів, про які мало хто знає",
              },
            ],
            disabled: false,
          },
          {
            id: "block4-lesson2",
            label: "Урок 2. Розбір інтерфейсу рекламного кабінету",
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D1%80%D0%BE%D0%B7%D0%B1%D1%96%D1%80%20%D1%96%D0%BD%D1%82%D0%B5%D1%80%D1%84%D0%B5%D0%B8%CC%86%D1%81%D1%83%20%D1%80%D0%BA.mov?alt=media&token=106b2bec-dbf8-44f2-a6b3-f43e0e36070d",
              },
            ],
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
          },
          {
            id: "block4-lesson3",
            label: "Урок 3. Як створити новий/або ще один рекламний кабінет",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D1%81%D1%82%D0%B2%D0%BE%D1%80%D0%B5%D0%BD%D0%BD%D1%8F%20%D1%80%D0%BA.mov?alt=media&token=804e3ef2-3e68-47de-8a91-99828e544903",
                text: (
                  <p>
                    <strong>Пам'ятайте</strong>, особистий рекламний кабінет
                    неможливо додати в бізнес менеджер, тому в такій ситуації{" "}
                    створити новий та працювати з нього
                  </p>
                ),
              },
            ],
          },
          {
            id: "block4-lesson4",
            label: "Урок 4. Білінг і все, що треба знати про цю функцію. НДС",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D0%9F%D0%A0%D0%9E%20%D0%9F%D0%9E%D0%94%D0%90%D0%A2%D0%9E%D0%9A%20%D0%9D%D0%90%20%D0%94%D0%9E%D0%94%D0%90%D0%9D%D0%A3%20%D0%92%D0%90%D0%A0%D0%A2%D0%86%D0%A1%D0%A2%D0%AC%20(%D0%9F%D0%94%D0%92)%20%D0%92%20%D0%A3%D0%9A%D0%A0%D0%90%D0%86%CC%88%D0%9D%D0%86.pdf?alt=media&token=d3d0cd65-a31a-4c20-97c8-88b0cb431cde",
                name: "Податок на додану вартість (ПДВ) в Україні",
              },
            ],
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D0%B1%D1%96%D0%BB%D1%96%D0%BD%D0%B3.mov?alt=media&token=01ef5711-a696-4169-92a4-17b45d915632",

                text: (
                  <>
                    <div>
                      Facebook працює за системою післяплати - спочатку крутимо
                      рекламу, потім платимо.
                    </div>
                    <div>
                      Через таку систему на нових рекламних акаунтах є обмеження
                      щодо максимального денного відкрутки бюджету (не більше
                      20$). Це означає, що Facebook нам не довіряє і заслужити
                      на його довіру ми можемо тільки справною поведінкою (не
                      порушувати правила і вчасно платити за рекламу). Тому якщо
                      у вас новий рк і ви захочете витратити більше, то у вас це
                      не вийде. У міру відкрутки реклами цей ліміт буде
                      збільшуватися.
                    </div>
                    <br />
                    <div>
                      Якщо у вас є резервні акаунти, обов'язково відкручуйте в
                      них мінімальні бюджети, щоб завжди мати розкачені рк для
                      швидкого переходу і можливості відкрутки великих бюджетів
                      (наприклад, при блокуванні).
                    </div>
                    <br />
                    <div>Види лімітів:</div>
                    <div>
                      - ліміт витрат рекламного облікового запису (по досягненні
                      припиниться відкрутка всіх рекламних кампаній);
                    </div>
                    <div>
                      - ліміт витрат для компанії (по досягненні ліміту компанія
                      припинить відкрутку);
                    </div>
                    <div>
                      - ліміт на денний бюджет (ліміт компанії/групи оголошень
                      на день - може змінюватись плюс-мінус 15%) або бюджет на
                      весь термін дії (реклама відкручується непропорційно і
                      бюджет витрачається так само; фейсбук сам його
                      розподіляє, витрачаючи великі бюджети в найбільш
                      ефективний на його думку (дні).
                    </div>
                    <div></div>
                  </>
                ),
              },
            ],
          },
          {
            id: "block4-lesson5",
            label: "Урок 5. Як подивитись видалені рекламні компанії",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D0%B2%D0%B8%D0%B4%D0%B0%D0%BB%D0%B5%D0%BD%D0%BD%D1%96.MP4?alt=media&token=c21e1d64-0f48-40b1-9c18-2dd82b12fb5e",
              },
            ],
          },
          {
            id: "block4-lesson6",
            label: "Урок 6. Запуск на всі рекламні цілі",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D0%B2%D1%81%D1%96%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%BD%D1%96%20%D1%86%D1%96%D0%BB%D1%96.mov?alt=media&token=e9066bb6-c83c-4e25-86a2-2f4667388366",
                text: (
                  <>
                    <div>
                      <strong>Ціль "Впізнаваність"</strong> - показує рекламу
                      людям, які з найбільшою ймовірністю запам'ятають її. Ця
                      категорія буде розрахована на покази реклами, але не на
                      кліки чи конверсії. Важливою особливістю будь-якої мети з
                      цієї категорії є низький СPM.
                    </div>
                    <br />
                    <div>Для чого підходить:</div>
                    <div>- Охоплення</div>
                    <div>- Приріст впізнаваності бренду</div>
                    <div>- Перегляди відео</div>
                    <div>- Впізнаваність адреси точки</div>
                    <br />
                    <div>Мета «Впізнаваність» найкраще використовувати:</div>
                    <div>- для просування розіграшів;</div>
                    <div>- для просування заходів Facebook;</div>
                    <div>
                      - для отримання передплатників для бізнес-сторінки
                      Facebook;
                    </div>
                    <div>- для великих компаній;</div>
                    <div>
                      - при реалізації товарів через постачальників - мережі
                      магазинів офлайн і за відсутності своєї точки продажів;
                    </div>
                    <div>
                      - коли потрібно нагадати про бренд або показати рекламу
                      всім, хто живе (для локальних бізнесів) і у нас немає
                      чітких KPI.
                    </div>
                    <br />
                    <div>
                      Взагалі сама метрика "прирост запам'ятовності, що
                      прогнозується" - дуже уявна, адже це все умовно. Насправді
                      неможливо розрахувати, скільки людей справді запам'ятає
                      компанію. Бізнесменам важливо заміряти суттєві показники,
                      отримувати віддачу в грошах, а ця мета не дає взагалі
                      жодних конкретних показників.
                    </div>
                    <div>
                      Оптимізація йде під покази, тобто завдання фейсбук -
                      показати якомога більше разів або більшій кількості людей.
                    </div>
                    <br />
                    <div>З цією цілью можна розробити окремі стратегії:</div>
                    <div>
                      1. Запустити на широку аудиторію для локального бізнесу і
                      налаштувати ретаргет на тих, хто подивився певний
                      відсоток;
                    </div>
                    <div>
                      2. Для інфобізнесу - запустити на "холодну" аудиторію
                      відео-знайомство зі спікером і потім налаштувати ретаргет
                      на тих, хто подивився певний відсоток і запропонувати їм
                      купівлю квитка на майстер-клас, наприклад;
                    </div>
                    <div>
                      3. Для збору схожої аудиторії за тим, хто подивився певний
                      відсоток відео.
                    </div>
                    <br />
                    <div>Важливо мати хороший оффер!</div>
                    <br />
                    <div>
                      <strong>Ціль «Трафік» </strong> - спрямовуйте людей у
                      певні місця призначення, наприклад на сайт, на сторінку в
                      Instagram, у додаток або захід на Facebook.
                    </div>
                    <br />
                    <div>Для чого підходить:</div>
                    <div>- Кліки за посиланнями</div>
                    <div>- Перегляди цільової сторінки</div>
                    <div>- Messenger та WhatsApp</div>
                    <div>- Виклики</div>
                    <br />
                    <div>Використовується у таких випадках:</div>
                    <div>
                      - для просування експертних та комерційних блогів (набір
                      підписників);
                    </div>
                    <div>- для просування Telegram-каналів;</div>
                    <div>
                      - альтернатива цілі "Конверсії", якщо "недостатньо
                      результатів";
                    </div>
                    <div>
                      - альтернатива цілі «Конверсії» при добре конвертуючому
                      сайті (варіант масштабування);
                    </div>
                    <div>- для ретаргетингу.</div>
                    <br />
                    <div>
                      <strong>Ціль "Залучення"</strong> (простими словами мета
                      повідомлення) - для отримання повідомлень, переглядів
                      відео, взаємодій з публікацією, позначок "Подобається" для
                      Сторінки та відповідей на запрошення.
                    </div>
                    <br />
                    <div>Для чого підходить:</div>
                    <div>- Messenger, Instagram та WhatsApp</div>
                    <div>- Перегляди відео</div>
                    <div>- Взаємодія із публікацією</div>
                    <div>- Конверсії</div>
                    <br />
                    <div>Коли можна використовувати ціль "Повідомлення":</div>
                    <div>- Збільшення заявок через кількість повідомлень;</div>
                    <div>- для роздачі купонів на знижку;</div>
                    <div>- якщо сайту немає або він слабкий;</div>
                    <div>- для ретаргетингу;</div>
                    <div>– для локальних бізнесів.</div>
                    <br />
                    <div>
                      Чому за ціль «Повідомлення» може не збігатися з кількістю
                      розпочатих листування з цифрою в рекламному кабінеті?
                      Метрика «Почате листування» показує кількість разів, коли
                      люди починали листування з вами після бездіяльності
                      щонайменше 7 днів.
                    </div>
                    <div>
                      Причин, з яких дані в кабінеті і за фактом відрізняються,
                      може бути кілька:
                    </div>
                    <div>
                      1. Ви не вважаєте спам за почате листування, а з реклами
                      буває приходить багато такого сміття
                    </div>
                    <div>
                      2. Ви вважаєте листування з людьми, які вам писали
                      протягом 7 днів, а такі користувачі не враховуються в
                      рекламному кабінеті.
                    </div>
                    <br />
                    <div>
                      Ця мета є недоступною для Європі. Тобто після запуску ви
                      бачите 0 охоплень та 0 показів.
                    </div>
                    <br />
                    <div>
                      Бажано перед запуском мати прописані скрипти. Їх може
                      написати під вашу компанію менеджер із продажу.
                    </div>
                    <br />
                    <div>
                      <strong>Скрипт</strong> - це алгоритм розмови з клієнтом,
                      де на будь-які його запитання або тези заздалегідь
                      підготовлені відповіді. По суті, це набір мовних шаблонів
                      або заготовок, що враховує різні варіанти поведінки
                      потенційного клієнта і дозволяє менеджеру з продажу швидко
                      орієнтуватися в ситуації. Правильно складені скрипти чи
                      сценарії продажів на 80% підвищують ефективність
                      переговорів та ведуть до укладання угоди.
                    </div>
                    <br />
                    <div>
                      Намагайтеся залучити клієнта до діалогу. Наприклад, вам
                      пише людина "+", щоб отримати безкоштовний гайд. Ви
                      починаєте з ним діалог: «Ви займаєтеся спортом вдома чи в
                      залі?», «Які цілі перед собою ставите?». Після відповідей
                      можна сказати, що гайд ідеально підійде та скинути на
                      нього посилання, а потім допродати продукт. Приклад того,
                      як зменшується шлях користувача при використанні цілі
                      "Повідомлення" на відміну від цілі "Трафік".
                    </div>
                    <br />
                    <div>
                      <strong>Ціль "Генерація лідів"</strong> використовуємо:
                    </div>
                    <div>- коли немає сайту чи він погано конвертує;</div>
                    <div>- коли сайт, для порівняння ціни ліда;</div>
                    <div>- коли погано упакований інстаграм;</div>
                    <div>- коли є відділ продажу, що швидко працює.</div>
                    <br />
                    <div>
                      Трафік із цієї цілі максимально холодний, адже люди бачили
                      лише рекламу і більше не знають про компанію нічого. Тому
                      важливо мати хорошого менеджера з продажу, який в курсі,
                      що людина не знайома з брендом. Продажник повинен бути
                      готовим розжувати "від" і "до", а для цього потрібно дуже
                      добре знати продукт. Із замовника завжди виходить поганий
                      продажник, це має бути спеціально навчена людина! Важливо
                      обробляти ліди дуже швидко – не більше 10-15 хвилин, а
                      бажано у перші хвилини.
                    </div>
                    <br />
                    <div>
                      Ліди зазвичай гірші, ніж з цілі "Продажі", але частіше
                      дешевші. Тому за наявності сайту також варто протестувати
                      цю ціль, щоб розуміти ціну якісного (валідного) ліда.
                    </div>
                    <br />
                    <div>
                      При використанні лід форм завжди інстаграм пропонуватиме
                      автозаповнення форм і людина може "випадково" натиснути
                      кнопку відправки мізинцем лівої ноги. Тому для підвищення
                      якості лідів можна скористатися додатковими питаннями. Для
                      запитань користувача можна створити набір умовних
                      відповідей, які будуть вибиратися на основі попередніх
                      відповідей людини.
                    </div>
                    <br />
                    <div>Конструктор політики конфіденційності:</div>
                    <br />
                    <div>
                      <a
                        href="https://www.cookieyes.com/privacy-policy-generator/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.cookieyes.com/privacy-policy-generator/
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://termly.io/products/privacy-policy-generator/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://termly.io/products/privacy-policy-generator/
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.privacypolicies.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.privacypolicies.com/
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://app-privacy-policy-generator.firebaseapp.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://app-privacy-policy-generator.firebaseapp.com/
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.iubenda.com/en/privacy-and-cookie-policy-generator#/wizard/site-url"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.iubenda.com/en/privacy-and-cookie-policy-generator#/wizard/site-url
                      </a>
                    </div>
                    <br />
                    <div>
                      Ліди будуть доступні для завантаження протягом 90 днів з
                      того моменту, як користувач відправив їх. Лайфхак: на
                      етапі налаштування останньої сторінки у формі генерації
                      лідів можна замість сайту вставити посилання на гугл
                      документи з якоюсь інфопродуктом-винагородою за заповнення
                      (наприклад, так можна роздавати гайди) та назвати кнопку
                      "завантажити".
                    </div>
                  </>
                ),
              },
            ],
          },
          {
            id: "block4-lesson7",
            label: "Урок 7. Запуск на різні цілі",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D1%86%D1%96%D0%BB%D1%8C%20%22%D1%83%D0%BF%D1%96%D0%B7%D0%BD%D0%B0%D0%B2%D0%B0%D0%BD%D1%96%D1%81%D1%82%D1%8C%22.MP4?alt=media&token=8246378d-c4f6-4d52-9c84-dee7244f2239",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D1%86%D1%96%D0%BB%D1%8C%20%22%D1%82%D1%80%D0%B0%D1%84%D1%96%D0%BA%22.MP4?alt=media&token=a740c71e-8c66-4388-988e-60f22df894c1",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D1%86%D1%96%D0%BB%D1%8C%20%D0%BF%D0%BE%D0%B2%D1%96%D0%B4%D0%BE%D0%BC%D0%BB%D0%B5%D0%BD%D0%BD%D1%8F.MP4?alt=media&token=0ecaa7df-b255-448b-8d48-76b3f3259394",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D1%86%D1%96%D0%BB%D1%8C%20%D0%BB%D1%96%D0%B4%D0%B8.MP4?alt=media&token=39bd767d-acc0-4d3c-8c7e-02a7d94b15f5",
              },
            ],
          },
          {
            id: "block4-lesson8",
            label: "Урок 8. Як запустити рекламу через Бізнес Сьют",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D0%B7%D0%B0%D0%BF%D1%83%D1%81%D0%BA%20%D1%87%D0%B5%D1%80%D0%B5%D0%B7%20%D0%B1%D1%81.MP4?alt=media&token=083dbb0c-8949-49e5-807c-3c3f77ca66c3",
              },
            ],
          },
          {
            id: "block4-lesson9",
            label: "Урок 9. Як запустити повідомлення в Европі",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <div> 1 спосіб:</div>
                    <ul>
                      <li>Створюємо кампанію з метою «Повідомлення»</li>
                      <li>
                        На свій розсуд ставимо СВО чи оптимізацію на рівні груп
                      </li>
                      <li>
                        Вибираємо месенджер на який направлятимемо людей
                        (Direct)
                      </li>
                      <li>Робимо наступні опції «Оптимізація та показ»</li>
                      <li>«Оптимізація для показу реклами»</li>
                      <li>«Клік по посиланню» і все готово</li>
                    </ul>
                    <div> 2 спосіб:</div>
                    <ul>
                      <li>
                        Створюємо спеціальне посилання https://ig.me/m/нікнейм
                        (сторінки на яку крутимо рекламу) * (без символу @)
                      </li>
                      <li>
                        На рівні оголошення в URL сайту, вставляємо створене
                        нами спеціальне посилання
                      </li>
                      <li>Запускаємо та готово</li>
                    </ul>
                    <div> 3 спосіб:</div>
                    <ul>
                      <li>Рекламний макет завантажуємо у сторіс чи пост</li>
                      <li>
                        Далі натискаємо кнопку «Просувати» і вибираємо мету
                        повідомлення – готово
                      </li>
                    </ul>
                    <div>
                      Обирайте будь-який із 3-х способів, пробуйте, тестуйте та
                      отримуйте круті результати
                    </div>
                  </>
                ),
              },
            ],
          },
          {
            id: "block4-lesson10",
            label: "Урок 10. Вибір плейсментів",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D0%B8.mov?alt=media&token=025374b8-20d5-4c95-a6ec-fb587b06d061",
              },
            ],
          },
          {
            id: "block4-lesson11",
            label: "Урок 11. Тестовий період",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D1%82%D0%B5%D1%81%D1%82%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%201%20%D1%87%D0%B0%D1%81%D1%82.mov?alt=media&token=dea23f2d-094f-4aab-bdd9-5c8ed5c22fc3",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D1%82%D0%B5%D1%81%D1%82%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%202%20%D1%87%D0%B0%D1%81%D1%82.mov?alt=media&token=a6c9439c-0e29-475c-8781-7d3a2593b482",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D0%B0%3A%D0%B2%20%D1%82%D0%B5%D1%81%D1%82%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F.png?alt=media&token=c992d61a-0ad6-4fd9-a1ec-dc810111cf64",
                name: "а:в тестування",
              },
            ],
          },
          {
            id: "block4-lesson12",
            label: "Урок 12. Бюджет на компанії або на групи ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D0%B1%D1%8E%D0%B4%D0%B6%D0%B5%D1%82%20%D1%81%D0%B2%D0%BE%20%D1%87%D0%B8%20%D0%BD%D0%B0%20%D0%B3%D1%80%D1%83%D0%BF%D0%BF%D1%83.mov?alt=media&token=6c886ec4-92d4-45fd-b5f8-5dd1b88a5ef5",
              },
            ],
          },
          {
            id: "block4-lesson13",
            label:
              "Урок 13. Технічна підтримка ФБ. Як написати та в яких випадках. Перевірки",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D1%82%D0%B5%D1%85%D0%BD%D1%96%D1%87%D0%BD%D0%B0%20%D0%BF%D1%96%D0%B4%D1%82%D1%80%D0%B8%D0%BC%D0%BA%D0%B0.mov?alt=media&token=b3bb744c-348b-4261-ada2-ed113dd97eac",
              },
            ],
          },
          {
            id: "block4-lesson14",
            label: "Урок 14. Як додати людей в БМ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content:
                  "Буває таке, що ми дали всі доступи до сторінок та кабінету вірно, але не розуміємо, чому якийсь елемент все одно не відкривається у нас (не підключився кабінет, бізнес сторінка, інстаграм тощо)",
              },
              {
                title: "Що робити в таких випадках?",
                content:
                  "Заходимо в рекламний кабінет клієнта від його імені (або ж це робить сам клієнт по нашим вказівкам)",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fimg_4_14_1.png?alt=media&token=363c2be1-a93a-4834-a4cf-8d0fc692e229",
                imageWidth: "800",
              },
              {
                content:
                  "Натискаєте “Налаштування компанії” та переходите в Бізнес Менеджер ",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fimg_4_14_2.png?alt=media&token=b66e5b20-a296-4850-947a-2a291d97beff",
                imageWidth: "800",
              },
              {
                content:
                  "Переходите в “Облікові записи” та обираєте той тип сторінки, доступа до якого у вас немає (наприклад, якщо до рекламного кабінету то... -",
              },
              {
                content:
                  "Ви обираєте “Рекламні облікові записи” - “Додати людину”",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fimg_4_14_3.png?alt=media&token=0e1925e9-93f3-4d89-912f-b87e98421cc5",
                imageWidth: "800",
              },
              {
                content:
                  "Обираєте себе (важливо! Щоб ви тут з’явились, ви маєте надати доступ до Бізнес Менеджеру через “Користувачі” - “Люди” - “Додати людей” і надати доступ по пошті) і даєте собі повний доступ",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fimg_4_14_4.png?alt=media&token=c28ab4a2-2b67-4c45-a00b-da512329b899",
                imageWidth: "800",
              },
              {
                title:
                  "ГОТОВО! Ви маєте доступ до тих робочих рекламних об’єктів, до яких не мали",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fimg_4_14_5.png?alt=media&token=153c5625-d4e5-4077-adc0-16cbe3fc1a60",
                imageWidth: "800",
              },
            ],
          },
          {
            id: "block4-lesson15",
            label: "Урок 15. Як підключити інстаграм, щоб запускати рекламу?",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content:
                  "Якщо у вас нова сторінка/або треба просто підключити інстаграм/або замінити та підключити інший - ви це зможете зробити за декілька хвилин.",
              },
              {
                title: "Куди треба натискати, щоб підключити інстаграм?",
                content:
                  "Заходимо на особистий профіль від його імені (або ж це робить сам клієнт по нашим вказівкам) та переходите в меню “Сторінки” обравши необхідну для вас бізнес сторінку",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fimg_4_15_1.png?alt=media&token=d8e58f3a-dd99-417b-91c5-f93a650a8a46",
                imageWidth: "800",
              },
              {
                content:
                  "Переключаєтесь на неї, щоб мати можливість робити всі необхідні технічні дії",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fimg_4_15_2.png?alt=media&token=649fd6e0-907f-465a-b201-c4b45ce02f09",
                imageWidth: "800",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fimg_4_15_3.png?alt=media&token=f747fca0-f0ab-4732-8b20-f5d08ba3bb6c",
                imageWidth: "800",
              },
              {
                content: "Обираємо “Усі інструменти” - “Налаштування сторінки”",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fimg_4_15_4.png?alt=media&token=ec382b1d-3bc6-45f6-932c-1d2d78eebaf5",
                imageWidth: "800",
              },
              {
                content:
                  "Тут у вас може бути на англійській мові або на українській “Linked Accounts”",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fimg_4_15_5.png?alt=media&token=6e468462-ae1b-4527-b915-9cc984a02027",
                imageWidth: "800",
              },
              {
                content:
                  "Обираємо інстаграм (тут же ми можемо підв’язати і WhatsApp при необхідності). Натискаємо “Підключити...”",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fimg_4_15_6.png?alt=media&token=a59dd82c-d84d-4cb1-95d0-8684b21564bb",
                imageWidth: "800",
              },
              {
                content: "Та пишемо данні на вхід",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fimg_4_15_7.png?alt=media&token=d199e4bf-f1aa-4baa-8ca5-0f561c9f0614",
                imageWidth: "800",
              },
              {
                title: "ГОТОВО! Наш інстаграм успішно підключився",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fimg_4_15_8.png?alt=media&token=830bcfc6-9166-4d1b-a1f5-160847eadfc3",
                imageWidth: "800",
              },
            ],
          },
          {
            id: "block4-lesson16",
            label: "Урок 16. Запис зум-зідзвону",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2Fvideo1883314030.mp4?alt=media&token=915f1a01-4e30-40ce-b029-226daeaa3e98",
              },
            ],
          },
          {
            id: "block4-lesson17",
            label: "Урок 17. Динамічні креативи",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%204%2F%D0%B4%D0%B8%D0%BD%D0%B0%D0%BC%D1%96%D1%87%D0%BD%D1%96%20%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B8.MP4?alt=media&token=309660cc-7690-452f-8c79-67edfd652bf6",
              },
            ],
          },
        ],
      },
      {
        id: "block5",
        label: "Модуль 5. Пошук клієнтів",
        description:
          "Ти навчишся створювати ефективні воронки продажів під будь-яку нішу, що збільшить рівень професіоналізму та якість результатів у проекті",
        disabled: false,
        children: [
          {
            id: "block5-lesson1",
            label: "Урок 1. Пошук клієнтів",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            info: [
              {
                content:
                  "Ти дізнаєшся всі можливі методи пошуку клієнтів, їх закриття та як з ними працювати - що просто НЕРЕАЛЬНО буде піти з навчання без клієнтів",
              },
            ],
            disabled: false,
          },
          {
            id: "block5-lesson2",
            label: "Урок 2. Методи пошуку клієнтів",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D0%BF%D0%BE%D1%88%D1%83%D0%BA%20%D0%BA%D0%BB%D1%96%D1%94%D0%BD%D1%82%D1%96%D0%B2.MP4?alt=media&token=78455ed2-bbdf-45f1-ad64-f8d23a0b4aef",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D0%9F%D0%BE%D1%88%D1%83%D0%BA%20%D0%BA%D0%BB%D1%96%D1%94%D0%BD%D1%82%D1%96%D0%B2.pdf?alt=media&token=b8daefb4-b9d1-426f-b9e4-f90810fdc340",
                name: "Методи пошуку",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2FIMG_6620.PNG?alt=media&token=f8467428-a943-4dbf-8ed5-0b7b954fc1aa",
                name: "Скрин розсилки",
              },
            ],

            disabled: false,
          },
          {
            id: "block5-lesson3",
            label: "Урок 3. Пошук клієнтів закордоном",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D0%BF%D0%BE%D1%88%D1%83%D0%BA%20%D0%B7%D0%B0%D0%BA%D0%BE%D1%80%D0%B4%D0%BE%D0%BD%D0%BD%D0%B8%D1%85%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D1%96%D0%B2.MP4?alt=media&token=76f35a7f-95c2-48f8-9ca0-11d5809894ab",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D0%9F%D0%BE%D1%88%D1%83%D0%BA%20%D0%B7%D0%B0%D1%80%D1%83%D0%B1%D1%96%D0%B6%D0%BD%D0%B8%D1%85%20%D0%BA%D0%BB%D1%96%D1%94%D0%BD%D1%82%D1%96%D0%B2.pdf?alt=media&token=c8318991-5ea6-43d6-9cf6-1e9862f7c541",
                name: "Пошук клієнтів закордоном",
              },
            ],
            disabled: false,
          },
          {
            id: "block5-lesson4",
            label: "Урок 4. Реклама на себе",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%B0%20%D0%BD%D0%B0%20%D1%81%D0%B5%D0%B1%D0%B5.MP4?alt=media&token=fad6679d-dfb4-45fa-b13a-1813272b0400",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D0%A0%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%B0%20%D0%BD%D0%B0%20%D1%81%D0%B5%D0%B1%D0%B5.pdf?alt=media&token=d878ee23-9d86-4fc4-83ac-8fbfed9be21c",
                name: "Реклама на себе",
              },
            ],
            disabled: false,
          },

          {
            id: "block5-lesson5",
            label: "Урок 5. Як проводити зідзвони з клієнтами",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%B6%20%D0%BD%D0%B0%20%D0%B7%D1%96%D0%B4%D0%B7%D0%B2%D0%BE%D0%BD%D0%B0%D1%85.MP4?alt=media&token=c1b75e30-4353-4ddf-9c45-1d79c231bfea",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D0%AF%D0%BA%20%D0%BF%D1%80%D0%BE%D0%B2%D0%BE%D0%B4%D0%B8%D1%82%D0%B8%20%D0%B7%D1%96%D0%B2%D0%BE%D0%BD%D0%B8%20%D0%B7%20%D0%BA%D0%BB%D1%96%D1%94%D0%BD%D1%82%D0%BE%D0%BC%20(1).pdf?alt=media&token=4ef47c02-37ac-4b6e-b38a-a3552eb8ae38",
                name: "Зідзвони з клієнтами",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D0%92%D1%96%D0%B4%D0%BF%D1%80%D0%B0%D1%86%D1%8E%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D0%B7%D0%B0%D0%BF%D0%B5%D1%80%D0%B5%D1%87%D0%B5%D0%BD%D1%8C.pdf?alt=media&token=70accc73-ae25-4973-9d6c-9a5833b2ab1d",
                name: "Відпрацювання заперечень",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D0%94%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%BD%D1%8F%20%D0%BA%D0%BB%D1%96%D1%94%D0%BD%D1%82%D0%B0%20%D0%B4%D0%BE%20%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D0%B8.docx?alt=media&token=c832bbf4-e406-4069-b7b4-3ed2276f31fa&_gl=1*xhfqu7*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODI3NDQuNjAuMC4w",
                name: "Доведення клієнта до оплати",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D0%A0%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D0%B0%D1%86%D1%96%D1%8F_%D0%B7_%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%B6%D1%96%D0%B2_%D0%B2_%D0%BF%D0%B5%D1%80%D0%B5%D0%BF%D0%B8%D1%81%D0%BA%D0%B0%D1%85.docx?alt=media&token=4943b3e4-f0fb-4a4f-ad11-ade854e2becd&_gl=1*efjzei*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODI3ODEuMjMuMC4w",
                name: "Рекомендація з продажів в переписках",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D0%AF%D0%9A%20%D0%9F%D0%A0%D0%90%D0%A6%D0%AE%D0%92%D0%90%D0%A2%D0%98%20%D0%86%D0%97%20%D0%97%D0%90%D0%9F%D0%95%D0%A0%D0%95%D0%A7%D0%95%D0%9D%D0%9D%D0%AF.docx?alt=media&token=be85299e-90e2-49d6-bb85-6a52876df1e4&_gl=1*h4w7s2*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODI4MTkuNjAuMC4w",
                name: "Як працювати із заперечення",
              },
            ],

            disabled: false,
          },

          {
            id: "block5-lesson6",
            label: "Урок 6. Коммунікація з клієнтами до та під час роботи",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            video: [
              {
                video: "",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%205%2F%D0%9A%D0%BE%D0%BC%D1%83%D0%BD%D1%96%D0%BA%D0%B0%D1%86%D1%96%D1%8F%20%D0%B7%20%D0%BA%D0%BB%D1%96%D1%94%D0%BD%D1%82%D0%B0%D0%BC%D0%B8.pdf?alt=media&token=c9738493-0e68-4395-99db-12a1edd4bee2",
                name: "Коммунікація з клієнтами до та під час роботи",
              },
            ],
            disabled: false,
          },

          {
            id: "block5-lesson7",
            label: "Урок 7. ДЗ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            info: [
              {
                content:
                  "Запакувати свій профіль, обрати для себе зручні методи пошуку клієнтів та почати активно закривати клієнтів протягом всього подальшого навчання.",
              },
              {
                content:
                  "Усі інструменти використовуйте, а по дорозі задавайте собі запитання: «Чи достатньо я роблю дій для досягнення мети X?»",
              },
            ],
            homework: true,
            disabled: false,
          },
        ],
      },
      {
        id: "block6",
        label: "Модуль 6. Особистий бренд",
        description: "",
        disabled: false,
        children: [
          {
            id: "block6-lesson1",
            label: "Урок 1. Особистий бренд",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            info: [
              {
                content:
                  "Якщо ти не знала(в) з чого почати в інстаграм - то цей модуль дасть усі знання та впевненість, щоб створити сильний особистий бренд",
              },
            ],
            disabled: false,
          },
          {
            id: "block6-lesson2",
            label: "Урок 2. Розпаковка особистості",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D1%80%D0%B0%D1%81%D0%BF%D0%B0%D0%BA%D0%BE%D0%B2%D0%BA%D0%B0%20%D0%BB%D0%B8%D1%87%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf?alt=media&token=f5e9dcc1-5be0-4768-96c4-7544bff5f229",
                name: "Розпаковка особистості",
              },
            ],
            disabled: false,
          },
          {
            id: "block6-lesson3",
            label: "Урок 3. ДЗ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            info: [
              {
                content:
                  "Ваша задача - заповнити повністю файл з розпаковки особистості. ВАЖЛИВО, цей файл - про вас, це те, що буде допомагати вам створювати контент та притягувати людей близьких по духу. Тому будьте максимально чесні з собою та своїми бажаннями.",
              },
            ],
            homework: true,
            disabled: false,
          },
          {
            id: "block6-lesson4",
            label: "Урок 4. Чому особистий бренд - це можливості",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",

            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D1%81%D1%82%D0%B8%D0%B8%CC%86%20%D0%B1%D1%80%D0%B5%D0%BD%D0%B4.MP4?alt=media&token=468db8e0-9442-47a0-9e41-80565e9474a0&_gl=1*12z20ty*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODI4OTkuNDkuMC4w",
                text: "Я бренд не закінчується, коли вимикається телефон. Будьте собою і ваше ім‘я буде працювати на вас!",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D0%9E%D1%81%D0%BE%D0%B1%D0%B8%D1%81%D1%82%D0%B8%D0%B8%CC%86%20%D0%B1%D1%80%D0%B5%D0%BD%D0%B4%20(%D1%83%D1%80%D0%BE%D0%BA%201).pdf?alt=media&token=b1a9e114-e2ec-403b-9a98-d4308e0fce65&_gl=1*1yfrilc*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODMxMDQuNjAuMC4w",
                name: "Особистий бренд",
              },
            ],
            disabled: false,
          },
          {
            id: "block6-lesson5",
            label: "Урок 5. Контент, який продає",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",

            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D0%BA%D0%BE%D0%BD%D1%82%D0%B5%D0%BD%D1%82.MP4?alt=media&token=610dbd6f-2597-496a-a48c-aa5259681335&_gl=1*17uverd*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODMyMTcuMTkuMC4w",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D0%9A%D0%BE%D0%BD%D1%82%D0%B5%D0%BD%D1%82%2C%20%D1%89%D0%BE%20%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D1%94%20(%D1%83%D1%80%D0%BE%D0%BA%203).pdf?alt=media&token=ca69bcc9-8c9a-45cc-bb7f-621b60fa0875&_gl=1*m4w6fk*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODMxNzYuNjAuMC4w",
                name: "Контент, що продає",
              },
            ],
            disabled: false,
          },
          {
            id: "block6-lesson6",
            label: "Урок 6. Формати контенту",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",

            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%20%D0%BA%D0%BE%D0%BD%D1%82%D0%B5%D0%BD%D1%82%D1%83.MP4?alt=media&token=f98e8e64-f8d6-44da-aabc-fdf8538d652e&_gl=1*p5ipjd*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODMyODEuNjAuMC4w",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D0%A4%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%20%D0%BA%D0%BE%D0%BD%D1%82%D0%B5%D0%BD%D1%82%D1%83%20(%D1%83%D1%80%D0%BE%D0%BA%205).pdf?alt=media&token=0cad9825-608a-4783-8fd6-557175d5aec4&_gl=1*1ppqyw3*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODMzMDguMzMuMC4w",
                name: "Формати контенту",
              },
            ],
            disabled: false,
          },
          {
            id: "block6-lesson7",
            label: "Урок 7. Все про сторіс та рілс",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",

            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D1%81%D1%82%D0%BE%D1%80%D1%96%D1%81.MP4?alt=media&token=f42abfee-4d34-47a5-b5bc-8db56eff5fb7&_gl=1*1x207m*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODMzODIuNDAuMC4w",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D0%A1%D1%82%D0%BE%D1%80%D1%96%D1%81%20(%D1%83%D1%80%D0%BE%D0%BA%204).pdf?alt=media&token=8206b59f-18b0-4a12-ac11-96b46e314ee4&_gl=1*1nf0c8d*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODMzNjIuNjAuMC4w",
                name: "Сторіс",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D0%86%D0%B4%D0%B5%D1%96%CC%88%20%D1%81%D1%82%D0%BE%D1%80%D1%96%D1%81%20%D1%82%D0%B0%20%D0%BF%D0%BE%D1%81%D1%82%D1%96%D0%B2%20%D0%B4%D0%BB%D1%8F%20%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82%D0%BE%D0%BB%D0%BE%D0%B3%D0%B0.docx?alt=media&token=8d1b1f50-a86e-4b83-9524-06faa7412c38&_gl=1*jzm2ks*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODM0NDcuNjAuMC4w",
                name: `Бонус: "50 ідей для контенту та сторіс"`,
              },
            ],
            disabled: false,
          },
          {
            id: "block6-lesson8",
            label:
              "Урок 8. Позицінування, яке допоможе відрізнятись від конкурентів",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",

            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D0%BF%D0%BE%D0%B7%D0%B8%D1%86%D1%96%D0%BE%D0%BD%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F.MP4?alt=media&token=cec025db-41fa-4ee4-951d-d1e18ea67066&_gl=1*1xdrjbb*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODM1NjAuMzguMC4w",
                text: (
                  <>
                    <div>
                      Позиціонування товару на ринку - маркетинговий процес, що
                      допомагає визначити виграшне становище серед конкурентів.
                    </div>
                    <br />
                    <div>
                      Мета позиціонування - знайти область, в якій бренд стане
                      єдиним у своєму роді та найкращим вибором. Суть
                      позиціонування - закріпити образ бренду у свідомості
                      цільової аудиторії. Без позиціонування товар не має
                      відмінних властивостей.
                    </div>
                    <br />
                    <div>
                      В цьому вам можуть допомогти наступні відповіді на
                      запитання:
                    </div>
                    <br />
                    <ul>
                      <li>Для кого призначено ваш продукт?</li>
                      <li>Які потреби він задовольняє?</li>
                      <li>Чим продукт відрізняється від аналогів?</li>
                      <li>Чому вигідно придбати ваш продукт?</li>
                      <li>За яких умов та коли він використовується?</li>
                    </ul>
                  </>
                ),
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%206%2F%D0%9F%D0%BE%D0%B7%D0%B8%D1%86%D1%96%D0%BE%D0%BD%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20(%D1%83%D1%80%D0%BE%D0%BA%206).pdf?alt=media&token=fcfe4a8b-74b8-4e7d-bbd8-83e1fed97192&_gl=1*ou2b71*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NTk4MjU3Ny40NS4xLjE2OTU5ODM1MzguNjAuMC4w",
                name: "Позиціонування",
              },
            ],
            disabled: false,
          },
          {
            id: "block6-lesson9",
            label: "Урок 9. Історія бренду/особистості ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            info: [
              { title: "ІСТОРІЯ БРЕНДУ" },
              {
                content: (
                  <>
                    <div>
                      Для того, щоб скласти історію вашого бренду чи автора,
                      необхідно відповісти на запитання нижче:
                    </div>
                    <ul>
                      <li>
                        Чому вас можна вважати успішним у своїй справі? Які ваші
                        головні здобутки?
                      </li>
                      <li>
                        Розкажіть коротко про свою біографію, які важливі та
                        яскраві моменти у вас були?
                      </li>
                      <li>Що цікавого у ній слід виділити?</li>
                      <li>Що вплинуло на формування вас як особистості?</li>
                      <li>Які складнощі зустрічалися на шляху?</li>
                      <li>
                        Як багато часу, зусиль та грошей знадобилося, щоб стати
                        тим, ким ви є зараз ?
                      </li>
                      <li>
                        Коли ви зрозуміли, що досягли певних успхів у цій сфері?
                        Чи є історія успіху?
                      </li>
                      <li>Розкажіть про те, як почанали</li>
                      <li>Чи є у Вас цікаві робочі історії?</li>
                      <li>Чому почали займатись саме цією нішею?</li>
                    </ul>
                    <br />
                    <div>
                      З отриманих відповідей складіть історію, використовуючи
                      вступні фрази. Фрази шаблонні, замінюйте їх синонімічними
                      виразами:
                    </div>
                    <br />
                    <div>«Зараз я...» - короткі регалії, авторитетність.</div>
                    <br />
                    <div>
                      «Але так було не завжди, раніше я теж була...» - проблеми
                      та болі аватара.
                    </div>
                    <br />
                    <div>
                      «Я почала шукати вихід, багато пробувала, витративши
                      багато сил, часу, грошей та енергії. Стало трохи виходити,
                      але...» - проблема більшості конкурентів як камінь
                      спотикання.
                    </div>
                    <br />
                    <div>
                      «І тут я знайшла «чарівну пігулку» .. » - історія як
                      дійшли до вирішення проблеми, як знайшли методику якої
                      навчаєте, результати по життю.
                    </div>
                    <br />
                    <div>
                      "Успіхи на сьогодні ..."- причина, чому ви цьому навчаєте.
                    </div>
                    <br />
                    <div>
                      Історія повинна складатися з підісторій, а саме: описи
                      конкретних життєвих ситуацій, з максимальною конкретикою,
                      деталізацією емоцій, що переживали та повним описом подій.
                    </div>
                  </>
                ),
              },
            ],

            disabled: false,
          },
          {
            id: "block6-lesson10",
            label: "Урок 10. Місія бренду",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",

            info: [
              {
                content: (
                  <>
                    <div>
                      Місія бренду - та велика причина, через яку створено
                      бізнес і проблема, яку він має вирішити. Вона формує
                      цінності та принципи бізнесу, відображає призначення
                      компанії, її позиціонування, визначає роль, яку грає в
                      суспільстві. Повинна бути сформульована чітко і зрозуміло
                      навіть для п'ятирічної дитини, близька до аватара.
                    </div>
                  </>
                ),
              },
            ],
            disabled: false,
          },
          {
            id: "block6-lesson11",
            label: "Урок 11. БРЕНД-МАНТРА",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",

            info: [
              {
                content: (
                  <>
                    <div>
                      Бренд-мантра - три слова, що характеризують враження, яке
                      має залишитися у клієнта після будь-якого дотику з
                      брендом: рекламою, лендінгу, відео, сайту тощо. Перше
                      слово є емоційним модифікатором, друге дескриптивним,
                      трете відповідає функціям бренду.
                    </div>
                    <br />
                    <div>Наприклад: </div>
                    <div>Макдональдс: їжа, радість, сім'я.</div>
                    <div>Найкі: автентичність, атлетичність, перфоманс.</div>
                  </>
                ),
              },
            ],
            disabled: false,
          },
          {
            id: "block6-lesson12",
            label: "Урок 12. Перевірка вашого профілю до продажів",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            info: [
              {
                content: (
                  <>
                    <div>
                      Профіль має відповідати певним критеріям, щоб навіть якщо
                      ви не ведете сторіс, новий підписник міг зрозуміти чим ви
                      займаєтеся і купити щось без вашої участі.
                    </div>
                    <br />
                    <div>Експертність = Довіра.</div>
                    <div>
                      Розкриваємо вашу експертність у блозі настільки, щоб
                      викликати довіру.
                    </div>
                    <div>
                      <br />
                      Біль ЦА.
                    </div>
                    <div>
                      Створюємо або потребу у вашому продукті, показуємо його
                      користь. Якщо ви не показуте користь і що всім потрібен
                      ваш продукт - він нікому не буде потрібен.
                    </div>
                    <br />
                    <div>Методологія.</div>
                    <div>
                      Показуємо унікальність вашої методопогії. Відповідність
                      часу і результату. Чим ви відрізнятесь від інших? і тд.
                    </div>
                    <br />
                    <div>Кейси та продукт.</div>
                    <div>
                      Демонструємо продукт і результат від нього. Що і як
                      працює. Показуємо продукт зсередини, як він виглядає, як
                      почувають себе ті, хто його проходив/пройшов. Обов'язково
                      - кейси в різних форматах (скріни, коментарі під постами,
                      відео-відгуки, ріла, сторіз і тд).
                    </div>
                    <br />
                    <div>Обробка сумнівів і заперечень.</div>
                    <div>
                      Регулярно спілкуйтесь з аудиторією, запитуйте, чого їм не
                      вистачає, чому не купують. Обробляйте відгуки з коментарів
                      та діректу. Але інколи, коли вас не розуміють чи не згодні
                      - не примушуйте, це просто не ваша цільова аудиторія.
                    </div>
                    <br />
                    <div>Заклик до дії.</div>
                    <div>
                      Нагадуйте людям, що саме ім треба зробити: перейти на сайт
                      / натиснути на кнопку / купити / написати в дірект тощо.
                      Після кожного хайлайтс має бути закрик до дії, має бути
                      зрозуміло куди ви ведете.
                    </div>
                  </>
                ),
              },
            ],

            disabled: false,
          },
        ],
      },
      {
        id: "block7",
        label: "Модуль 7. Створення стратегій",
        description:
          "Прокачаєш знання в маркетингу, які допомагатимуть робити круті результати клієнту та буде відрізняти тебе від конкурентів",
        disabled: false,
        children: [
          {
            id: "block7-lesson1",
            label: "Урок 1. Вступ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            info: [
              {
                content:
                  "Ти зрозумієш, як створюються стратегії, які приносять результат клієнтам та отримаєш приклади шаблонів, які вже зможеш використовувати в роботі",
              },
            ],
            disabled: false,
          },
          {
            id: "block7-lesson2",
            label: "Урок 2.Створення стратегій",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%207%2F%D1%81%D1%82%D1%80%D0%B0%D1%82%D0%B5%D0%B3%D1%96%D1%96%CC%88.mov?alt=media&token=7273eedb-fe40-4527-956b-5a2f0aae5075&_gl=1*tt5mhf*_ga*MTc0OTgwOTU3OS4xNjkxMDAwNzE2*_ga_CW55HF8NVT*MTY5NjYyNTgyNy42Ny4xLjE2OTY2MjU4NDcuNDAuMC4w",
              },
            ],
          },
          {
            id: "block7-lesson3",
            label: "Урок 3.Приклади шаблонів стратегій ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            file: [
              {
                file: "https://docs.google.com/spreadsheets/d/12VovHnSnDE5jPkq9TJckxr9SZyJEmKNM_Y4W9bAmEeY/edit?usp=sharing",
                name: "Таблиця конкурентів",
              },
            ],
          },
          {
            id: "block7-lesson4",
            label: "Урок 4. Д/З",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            homework: true,
            info: [
              {
                content:
                  "Проаналізувати та створити стратегію для вашого проекту/клієнта",
              },
            ],
          },
          {
            id: "block7-lesson5",
            label: "Урок 5. Зум-зідзвон",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
          },
        ],
      },
      {
        id: "block8",
        label: "Модуль 8. Воронки продажів",
        description:
          "Після ти зможеш зробити клієнту ВАУ-ефект, адже даватимеш не тільки заявки, а продажі, після чого - ЦЕЙ КЛІЄНТ З ТОБОЮ НАЗАВЖДИ",
        disabled: false,
        children: [
          {
            id: "block8-lesson1",
            label: "Урок 1. Вступ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content:
                  "Ти навчишся створювати ефективні воронки продажів під будь-яку нішу,що збільшить рівень професіоналізму та якість результатів у проекти",
              },
            ],
          },
          {
            id: "block8-lesson2",
            label:
              "Урок 2. Що таке воронки продажів? Які бувають та як їх використовувати в проекті?",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%208%2F%D0%B2%D0%BE%D1%80%D0%BE%D0%BD%D0%BA%D0%B8%20%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%B6%D1%96%D0%B2.MP4?alt=media&token=7e654a5c-1bee-42e5-9806-104542e9d408&_gl=1*1pstfu3*_ga*MTY4OTY5MzQzLjE2OTcwOTUzMzQ.*_ga_CW55HF8NVT*MTY5NzI3MzYyNS43LjEuMTY5NzI3MzY0Ni4zOS4wLjA",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%208%2F%D0%92%D0%BE%D1%80%D0%BE%D0%BD%D0%BA%D0%B0%20%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%B6%D1%96%D0%B2.pdf?alt=media&token=12b91988-20f9-4b56-8773-e24452525630&_gl=1*duwo1c*_ga*MTY4OTY5MzQzLjE2OTcwOTUzMzQ.*_ga_CW55HF8NVT*MTY5NzI3MzYyNS43LjEuMTY5NzI3Mzg2NS4yNi4wLjA.",
                name: "Розбір воронки",
              },
            ],
          },
          {
            id: "block8-lesson3",
            label:
              "Урок 3. Етапи створення воронки продажів. Розбір воронок продажів на прикладах реальних кліентів",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%208%2F%D0%B5%D1%82%D0%B0%D0%BF%D0%B8%20%D0%B2%D0%BE%D1%80%D0%BE%D0%BD%D0%BA%D0%B8%20%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%B6%D1%96%D0%B2.MP4?alt=media&token=778bfb26-d7ba-4da7-ac96-ed8099ffda5a&_gl=1*cj1erd*_ga*MTY4OTY5MzQzLjE2OTcwOTUzMzQ.*_ga_CW55HF8NVT*MTY5NzI3MzYyNS43LjEuMTY5NzI3Mzc0Ny42MC4wLjA",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%208%2F%D0%A0%D0%BE%D0%B7%D0%B1%D1%96%D1%80%20%D0%B2%D0%BE%D1%80%D0%BE%D0%BD%D0%BA%D0%B8.pdf?alt=media&token=1dc18648-9fe0-4977-921c-1127161febd3&_gl=1*1grves6*_ga*MTY4OTY5MzQzLjE2OTcwOTUzMzQ.*_ga_CW55HF8NVT*MTY5NzI3MzYyNS43LjEuMTY5NzI3MzgzMi41OS4wLjA.",
                name: "Етапи воронки продажів",
              },
            ],
          },
          {
            id: "block8-lesson4",
            label: "Урок 4. Ще більше про продажі. Термінологія",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <div>
                      <strong>Апсел</strong> - це метод продажів, за допомого
                      якого бренди збільшують прибуток, мотивуючи клієнтів
                      купувати більш модернізовані та дорогі товари з однієї
                      продуктової лінійки.
                    </div>
                    <br />
                    <div>
                      <strong>Кросс-селл</strong> - це спонукання покупця
                      придбати товари з інших категорій (супутні товари). Тобто
                      суть полягає в тому, щоб запропонувати покупцеві додаткові
                      товари, які могли б його зацікавити.
                    </div>
                    <br />
                    <div>
                      <strong>Даунсейл</strong> - це метод продажу товару або
                      послуги дешевше від основного продукту (метод
                      використовують коли клієнт не готовий на ціну основного
                      продукту через дорогу ціну для нього).
                    </div>
                    <br />
                    <div>
                      <strong>Бандл</strong> - група, яка складається з
                      декількох товарів/послуг та продається як одне ціле.
                    </div>
                    <br />
                  </>
                ),
              },
            ],
          },
          {
            id: "block8-lesson5",
            label: "Урок 5. Д/З",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            homework: true,
            info: [
              {
                content:
                  "Створити воронку продажів під себе/свою нішу/свого клієнта та розписати кожен етап з різними варіантами продуктів/товарів",
              },
            ],
          },
          {
            id: "block8-lesson6",
            label: "Урок 6. Запис зум зідзвону",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%208%2Fvideo1447973249.mp4?alt=media&token=676244d7-ba79-4010-892d-ae332b94b639&_gl=1*1dtnnhu*_ga*MTY4OTY5MzQzLjE2OTcwOTUzMzQ.*_ga_CW55HF8NVT*MTY5NzI3MzYyNS43LjEuMTY5NzI3NDE4Ny41Ni4wLjA",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%208%2Fvideo2447973249.mp4?alt=media&token=0a0be942-fb2a-416a-908e-086a0953d95b&_gl=1*lzpflq*_ga*MTY4OTY5MzQzLjE2OTcwOTUzMzQ.*_ga_CW55HF8NVT*MTY5NzI3MzYyNS43LjEuMTY5NzI3NDIxOS4yNC4wLjA",
              },
            ],
          },
        ],
      },
      {
        id: "block9",
        label: "Модуль 9. Створення креативів",
        description:
          "Ти нарешті навчишся створювати креативи, які будуть продавати",
        disabled: false,
        children: [
          {
            id: "block9-lesson1",
            label: "Урок 1",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <div>
                    Ти нарешті навчишся створювати креативи, які будуть
                    продавати.
                    <strong>Запрошений спікер Данило Роговий</strong>
                  </div>
                ),
              },
            ],
          },
          {
            id: "block9-lesson2",
            label: "Урок 2. Вступний урок",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%B2%D1%81%D1%82%D1%83%D0%BF%D0%BD%D0%B8%D0%B8%CC%86%20%D1%83%D1%80%D0%BE%D0%BA.mp4?alt=media&token=fdee5c7a-40b9-440c-92d5-0aa5f812cf31",
              },
            ],
          },
          {
            id: "block9-lesson3",
            label: "Урок 3. Інтерфейс та інструменти додатку",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%86%D0%BD%D1%82%D0%B5%D1%80%D1%84%D0%B5%D0%B8%CC%86%D1%81%20%D1%82%D0%B0%20%D1%96%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%B8%20%D0%B4%D0%BE%D0%B4%D0%B0%D1%82%D0%BA%D1%83.mp4?alt=media&token=3a045e91-3d08-44c5-80fa-d5511a85b4ba",
              },
            ],
          },
          {
            id: "block9-lesson4",
            label: "Урок 4. Візуальна частина робіт",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%92%D1%96%D0%B7%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%20%D1%87%D0%B0%D1%81%D1%82%D0%B8%D0%BD%D0%B0%20%D1%80%D0%BE%D0%B1%D1%96%D1%82.mp4?alt=media&token=f7593e19-b540-47d7-a750-b18a12293050",

                text: (
                  <>
                    <div>Шрифти, якими я користуюся щодня ↓</div>
                    <a href="https://t.me/+OB2jftHN1H45ZDE6">
                      https://t.me/+OB2jftHN1H45ZDE6
                    </a>
                    <br /> <br />
                    <em>Як встановити шрифти, як знайти шрифт по фото?</em>
                    <div>Дивись останні дописи в каналі</div>
                    <br />
                    <div>Телеграм-канал зі шрифтами ↓</div>
                    <a href=" t.me/+kA8cP-GDvIBhMTli">
                      https://t.me/+kA8cP-GDvIBhMTli
                    </a>
                  </>
                ),
              },
            ],
          },
          {
            id: "block9-lesson5",
            label: "Урок 5. Натхнення та пошук ідей",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BD%D0%B0%D1%82%D1%85%D0%BD%D0%B5%D0%BD%D0%BD%D1%8F%20%D1%82%D0%B0%20%D0%BF%D0%BE%D1%88%D1%83%D0%BA%20%D1%96%D0%B4%D0%B5%D0%B8%CC%862.mp4?alt=media&token=b9ac2cf7-8c95-4f14-af5f-e88d7c2d9c86",
                text: (
                  <>
                    <div>
                      Телеграм-канали з референсами та портфоліо дизайнерів у
                      Телеграм ↓
                    </div>
                    <a href="https://t.me/creativehubb">
                      💣https://t.me/creativehubb
                    </a>
                    <br />
                    <a href="https://t.me/press_f_its_ref">
                      💣https://t.me/press_f_its_ref
                    </a>
                    <br /> <br />
                    <div>Інстаграм акаунти дизайнерів з усього світу ↓</div>
                    <a href="https://www.instagram.com/potok.msk/?igshid=NmZiMzY2Mjc%3D">
                      https://www.instagram.com/potok.msk/?igshid=NmZiMzY2Mjc%3D
                    </a>
                    <a href="https://instagram.com/letov_dsgn?igshid=NmZiMzY2Mjc=">
                      https://instagram.com/letov_dsgn?igshid=NmZiMzY2Mjc=
                    </a>
                    <a href="https://www.instagram.com/ronaldoodesign/?igshid=MjkzY2Y1YTY%3D">
                      https://www.instagram.com/ronaldoodesign/?igshid=MjkzY2Y1YTY%3D
                    </a>
                    <a href="https://www.instagram.com/juanvince_/?igshid=MjkzY2Y1YTY%3D">
                      https://www.instagram.com/juanvince_/?igshid=MjkzY2Y1YTY%3D
                    </a>
                    <a href="https://www.instagram.com/uidesignpattern/?igshid=MjkzY2Y1YTY%3D">
                      https://www.instagram.com/uidesignpattern/?igshid=MjkzY2Y1YTY%3D
                    </a>
                    <a href="https://www.instagram.com/semen1yk_design/?igshid=MjkzY2Y1YTY%3D">
                      https://www.instagram.com/semen1yk_design/?igshid=MjkzY2Y1YTY%3D
                    </a>
                    <a href="https://www.instagram.com/cyrus_thekingarts/">
                      https://www.instagram.com/cyrus_thekingarts/
                    </a>
                    <a href="https://www.instagram.com/silvaniodsgn/">
                      https://www.instagram.com/silvaniodsgn/
                    </a>
                    <br /> <br />
                    <div>
                      Моє телеграм-портфоліо —{" "}
                      <a href="https://t.me/+5W-l5iXuMVU3ZDVi">
                        https://t.me/+5W-l5iXuMVU3ZDVi
                      </a>
                    </div>
                    <br />
                    <div>
                      Канал, куди я зберігаю цікаві ідеї —{" "}
                      <a href="https://t.me/+O0UikvhmUkg0N2E6">
                        https://t.me/+O0UikvhmUkg0N2E6
                      </a>
                    </div>
                  </>
                ),
              },
            ],
          },
          {
            id: "block9-lesson6",
            label: "Урок 6. Монтаж відео. Функціонал по роботі з монтажем",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BC%D0%BE%D0%BD%D1%82%D0%B0%D0%B6%20%D0%B2%D1%96%D0%B4%D0%B5%D0%BE.MP4?alt=media&token=acb74c32-38c0-42f4-a753-a6326edea300",
              },
            ],
          },
          {
            id: "block9-lesson7",
            label: "Урок 7. Анімація та робота з ними ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%B0%D0%BD%D1%96%D0%BC%D0%B0%D1%86%D1%96%D1%8F.MP4?alt=media&token=74fc6e8c-2c66-47ba-8ca5-a87d1109c59e",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D1%80%D0%BE%D0%B7%D0%BC%D0%B8%D1%82%D1%82%D1%8F%20%D0%B2%20%D1%80%D1%83%D1%81%D1%96.MP4?alt=media&token=c384b9a5-924f-46d7-ba14-a8d92006958b",

                text: (
                  <>
                    <div>Посилання на паки анімацій:</div>
                    <a href="https://alight.link/baxA4CNtUJhEZR3v9?source=link">
                      https://alight.link/baxA4CNtUJhEZR3v9?source=link
                    </a>
                    <br />
                    <a href="https://alight.link/RDt83QHjb3xnFHTT7?source=link">
                      https://alight.link/RDt83QHjb3xnFHTT7?source=link
                    </a>
                    <br />
                    <a href="https://alight.link/YX5LUvjAynPF2XaU8?source=link">
                      https://alight.link/YX5LUvjAynPF2XaU8?source=link
                    </a>
                    <br />
                    <a href="https://alight.link/hQdV7G8Jps6vcn4a9?source=link">
                      https://alight.link/hQdV7G8Jps6vcn4a9?source=link
                    </a>
                    <br />
                    <div>
                      Ще більше по запиту в Ютубі «shake alight motion» і схожим
                      на цей
                    </div>
                  </>
                ),
              },
            ],
          },
          {
            id: "block9-lesson8",
            label:
              "Урок 8. Музика - де її шукати, як накладати, обрізати та все інше, що з нею можна придумати",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BC%D1%83%D0%B7%D0%B8%D0%BA%D0%B0.MP4?alt=media&token=2565ed51-874b-4905-bc42-9f832f0a9109",
                text: (
                  <>
                    <div>Посилання на ТТ з музлом ↓</div>
                    <br />
                    <div>
                      Ну дуже багато крутих треків в цьому ТТ акаунті — завжди
                      юзаю
                    </div>
                    <a href="https://www.tiktok.com/@andresvidoza?_t=8WNE13yXYWc&_r=1">
                      📎https://www.tiktok.com/@andresvidoza?_t=8WNE13yXYWc&_r=1
                    </a>
                    <br />
                    <div>Пару треків юзав у цього, теж топ</div>
                    <a href="https://www.tiktok.com/@vhackerr?_t=8WNE9R8IuxC&_r=1">
                      📎https://www.tiktok.com/@vhackerr?_t=8WNE9R8IuxC&_r=1
                    </a>
                    <br />
                    <div>
                      Тут тоже гуд, але в деяких чутно звуки авто, тож їх не
                      обирайте
                    </div>
                    <a href="https://www.tiktok.com/@brxd.cars?_t=8WNEBkQcQg2&_r=1">
                      📎https://www.tiktok.com/@brxd.cars?_t=8WNEBkQcQg2&_r=1
                    </a>
                    <br />
                    <div>
                      Якась агенція по створенню контенту — в них можна
                      надихнутися та спиздити якийсь тречок
                    </div>
                    <a href="https://www.tiktok.com/@finch.buro?_t=8WNEEX3069m&_r=1">
                      📎https://www.tiktok.com/@finch.buro?_t=8WNEEX3069m&_r=1
                    </a>
                    <br />
                    <div>Просто тіп з ок музлом також</div>
                    <a href="https://www.tiktok.com/@willy_iffland?_t=8WNEJ3yVSEA&_r=1">
                      📎https://www.tiktok.com/@willy_iffland?_t=8WNEJ3yVSEA&_r=1
                    </a>
                    <br />
                    <div>Просто тіп тоже</div>
                    <a href="https://www.tiktok.com/@sir_bucks?_t=8WNEPMeVnwj&_r=1">
                      📎https://www.tiktok.com/@sir_bucks?_t=8WNEPMeVnwj&_r=1
                    </a>
                    <br />
                    <div>Ай харашооо</div>
                    <a href="https://www.tiktok.com/@eladiojm?_t=8agjQPivRmY&_r=1">
                      📎https://www.tiktok.com/@eladiojm?_t=8agjQPivRmY&_r=1
                    </a>
                    <br />
                    <div>Топ тіп</div>
                    <a href="https://www.tiktok.com/@nvzion?_t=8WNEaspXDtN&_r=1">
                      📎https://www.tiktok.com/@nvzion?_t=8WNEaspXDtN&_r=1
                    </a>
                    <br />
                    <div>ТУТ ДУЖЕ-ДУЖЕ БАГАТО МУЗЛА ПІДХОДЯЩОГО</div>
                    <a href="https://www.tiktok.com/@xxtristanxo?_t=8WNEcEliJtN&_r=1">
                      📎https://www.tiktok.com/@xxtristanxo?_t=8WNEcEliJtN&_r=1
                    </a>
                    <br />
                    <div>Також топ</div>
                    <a href="https://www.tiktok.com/@vinceddo?_t=8WNEl8lHTpG&_r=1">
                      📎https://www.tiktok.com/@vinceddo?_t=8WNEl8lHTpG&_r=1
                    </a>
                    <br />
                    <div>І останній</div>
                    <a href="https://www.tiktok.com/@lukaszvikas?_t=8WNEnoPepnB&_r=1">
                      📎https://www.tiktok.com/@lukaszvikas?_t=8WNEnoPepnB&_r=1
                    </a>
                  </>
                ),
              },
            ],
          },
          {
            id: "block9-lesson9",
            label: "Урок 9. 🔥БОНУС🔥",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <div>
                      за відмітку в сторіс мене та Дані ( його інст:{" "}
                      <a href="https://instagram.com/rogov.pr?igshid=MzRlODBiNWFlZA==">
                        https://instagram.com/rogov.pr?igshid=MzRlODBiNWFlZA==
                      </a>{" "}
                      )
                    </div>
                    <div>ДАРУЄМО ДОДАТКОВІ УРОКИ ПО КРЕАТИВАМ</div>
                    <br />
                    <div>
                      (*це може бути сторіс з відгуком про
                      навчання/матеріал/спікерів; процес вашого навчання чи інше
                      з відміткою 2-х експертів)
                    </div>
                  </>
                ),
              },
            ],
          },
          {
            id: "block9-lesson10",
            label: "Урок 10. Майстер-класи. Розбір створення рекламного банеру",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BC%D0%B0%D1%81%D1%82%D0%B5%D1%80-%D0%BA%D0%BB%D0%B0%D1%81%201.mp4?alt=media&token=8454dc6a-2275-4d4e-89c6-c667508e90ef",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BC%D0%B0%D1%81%D1%82%D0%B5%D1%80-%D0%BA%D0%BB%D0%B0%D1%81%202.mp4?alt=media&token=693b54fe-7ca9-4cce-99d2-dca119ed4109",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BC%D0%B0%D1%81%D1%82%D0%B5%D1%80-%D0%BA%D0%BB%D0%B0%D1%81%203.mp4?alt=media&token=8e92b15f-ae3e-4bfe-ad1e-a812b874e467",
              },
            ],
          },
          {
            id: "block9-lesson11",
            label: "Урок 11. Ідеї для створення відео",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BA%D0%BE%D1%80%D0%BE%D1%82%D0%BA%D0%B5%20%D0%B2%D1%96%D0%B4%D0%B5%201.MOV?alt=media&token=8f141794-f281-4a07-ada9-cc15e661658d",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BA%D0%BE%D1%80%D0%BE%D1%82%D0%BA%D0%B5%20%D0%B2%D1%96%D0%B4%D0%B5%D0%BE%202.MOV?alt=media&token=d5425094-aaa5-4c98-a750-76394a240ec3",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BA%D0%BE%D1%80%D0%BE%D1%82%D0%BA%D0%B5%20%D0%B2%D1%96%D0%B4%D0%B5%D0%BE%203.MOV?alt=media&token=0dc1ef03-7a41-4b75-87e7-f6f584602e32",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BA%D0%BE%D1%80%D0%BE%D1%82%D0%BA%D0%B5%20%D0%B2%D1%96%D0%B4%D0%B5%D0%BE%204.MOV?alt=media&token=a6753248-8b3e-40f9-a0e5-9346fa1cffcc",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BA%D0%BE%D1%80%D0%BE%D1%82%D0%BA%D0%B5%20%D0%B2%D1%96%D0%B4%D0%B5%D0%BE%205.MOV?alt=media&token=1d3751f5-c45f-4847-9eb1-bef8f4b9697b",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BA%D0%BE%D1%80%D0%BE%D1%82%D0%BA%D0%B5%20%D0%B2%D1%96%D0%B4%D0%B5%D0%BE%206.MOV?alt=media&token=b2ab271e-e3c6-4f31-9a94-3e4c2bb5c19d",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BA%D0%BE%D1%80%D0%BE%D1%82%D0%BA%D0%B5%20%D0%B2%D1%96%D0%B4%D0%B5%D0%BE%207.MP4?alt=media&token=6a40703e-58c1-4342-91c7-d73fece6065a",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BA%D0%BE%D1%80%D0%BE%D1%82%D0%BA%D0%B5%20%D0%B2%D1%96%D0%B4%D0%B5%D0%BE%208.MP4?alt=media&token=ff028dc4-ad0a-4265-b8d9-08262f151184",
              },
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%BA%D0%BE%D1%80%D0%BE%D1%82%D0%BA%D0%B5%20%D0%B2%D1%96%D0%B4%D0%B5%D0%BE%209.MP4?alt=media&token=df9a7ba4-8abd-47f8-bdfc-3104302dd7aa",
                text: (
                  <>
                    <div>І ось такі приколи ↓</div>

                    <a href="https://vm.tiktok.com/ZMYHFHMn4/">
                      📎 https://vm.tiktok.com/ZMYHFHMn4/
                    </a>
                    <br />
                    <a href="https://vm.tiktok.com/ZMYHFu8n7/">
                      📎 https://vm.tiktok.com/ZMYHFu8n7/
                    </a>
                    <br />
                    <a href="https://vm.tiktok.com/ZMYHF5ake/">
                      📎 https://vm.tiktok.com/ZMYHF5ake/
                    </a>
                    <br />
                    <br />
                    <div>Як це юзати? ↓</div>
                    <br />
                    <div>Популярні звукові ефекти для відео ↓</div>
                    <a href="https://youtu.be/H6QFS74faro">
                      📎 https://youtu.be/H6QFS74faro
                    </a>
                    <br />
                    <a href="https://youtu.be/w3HrQHvZuHY">
                      📎 https://youtu.be/w3HrQHvZuHY
                    </a>
                    <br />
                    <a href="https://youtu.be/8Iv0fVYwxdk">
                      📎 https://youtu.be/8Iv0fVYwxdk
                    </a>
                    <br />
                    <br />
                    <div>
                      ⏺ Найлегше, це зробити запис екрану, обрізати і накласти
                      на ваш проєкт
                    </div>
                    <br />
                    <div>
                      Ще більше таких ефектів можна знайти по схожим запитам у
                      тому ж Ютубі😘
                    </div>
                  </>
                ),
              },
            ],
          },
          {
            id: "block9-lesson12",
            label: "Урок 12. Основні розміри макетів для реклами",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <div>Instagram: </div>
                    <div>Stories 1 080х1 920</div>
                    <div>Стрічка 1 080х1 350 або 1 080х1 080</div>
                    <br />
                    <div>Facebook: </div>
                    <div>Stories 1 080х1 920</div>
                    <div>Стрічка 1 080х1 350 або 1 080х1 080</div>
                    <div>Правий стовбик 1 200х1 200</div>
                    <div>Marketplace 1 200х1 200</div>
                    <br />
                    <div>Audience Network: </div>
                    <div>
                      Нативна реклама, креативи та рекламні вставки 1 200х628
                    </div>
                    <br />
                    <div>Messenger: </div>
                    <div>Stories 1 080х1 920</div>
                    <div>Вхідні 1 200х1 200</div>
                    <div>Рекламні повідомлення 1 200х628</div>
                  </>
                ),
              },
            ],
          },
          {
            id: "block9-lesson13",
            label: "Урок 13. Що писати на креативах, щоб вони спрацювали",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%A9%D0%BE%20%D0%BF%D0%B8%D1%81%D0%B0%D1%82%D0%B8%20%D0%BD%D0%B0%20%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0%D1%85%2C%20%D1%89%D0%BE%D0%B1%20%D0%B2%D0%BE%D0%BD%D0%B8%20%D1%81%D0%BF%D1%80%D0%B0%D1%86%D1%8E%D0%B2%D0%B0%D0%BB%D0%B8.MP4?alt=media&token=e4fa2d5a-e49f-463c-a00a-a45dec937c17",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%A0%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%BD%D0%B8%D0%B8%CC%86%20%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2.pdf?alt=media&token=6714be31-1170-462c-a9b9-52345a9acfc6",
                name: "Рекламний креатив",
              },
            ],
          },
          {
            id: "block9-lesson14",
            label: "Урок 14. Приблизне розрахування бюджету для різних країн ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D1%84%D0%BE%D1%82%D0%BE%20%D0%BA%D0%B0%D1%80%D1%82%D0%B8%20%D1%81%D0%B2%D1%96%D1%82%D1%83.jpeg?alt=media&token=9c004ef1-3d3c-4e3e-b093-6c59bfe33043",
                name: "Карта світу",
              },
            ],
            info: [
              {
                content: (
                  <>
                    <div>TIER 3 ~ від 2$ на один креатив</div>
                    <div>TIER 2 ~ х2 бюджету від TIER</div>
                    <div>TIER 1 ~ х3-х5 бюджету від TIER 3</div>
                  </>
                ),
              },
            ],
          },
          {
            id: "block9-lesson15",
            label: "Урок 15. Що таке офер та як це використовувати в рекламі",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%A9%D0%BE_%D1%82%D0%B0%D0%BA%D0%B5_%D1%81%D1%84%D0%B5%D1%80_%D1%82%D0%B0_%D1%8F%D0%BA_%D0%B2%D0%B8%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D1%82%D0%BE%D0%B2%D1%83%D0%B2%D0%B0%D1%82%D0%B8_%D1%86%D0%B5_%D0%B2_%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D1%96.pdf?alt=media&token=054d5b90-552d-41b8-ab80-a158870b0264",
                name: "Що таке офер та як це використовувати в рекламі",
              },
            ],
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%A9%D0%BE%20%D1%82%D0%B0%D0%BA%D0%B5%20%D0%BE%D1%84%D0%B5%D1%80%20%D1%82%D0%B0%20%D1%8F%D0%BA%20%D1%86%D0%B5%20%D0%B2%D0%B8%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D1%82%D0%BE%D0%B2%D1%83%D0%B2%D0%B0%D1%82%D0%B8%20%D0%B2%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D1%96.MP4?alt=media&token=df90e2ec-dbb1-4554-91ae-57e338cf4684",
                text: (
                  <>
                    <strong>Не забувайте про головні потреби людей! </strong>
                    <br />
                    <br />
                    <div>
                      Наприклад, головна потреба для мами новонародженого –
                      безпека дитини. Справжня потреба - забезпечити безпеку
                      дитини. Тому офер для такого "болю" може бути наступним:
                      «Як виростити здорову дитину? Курс…». такий оффер навряд
                      чи працюватиме добре. Все, що пов'язане зі здоров'ям та
                      безпекою краще працює через страх втрати. Тому
                      переформулювати першу пропозицію можна так: «Життя дитина
                      коштує для вас 1000 грн? (тут ціна курсу)», «Як курс
                      врятував життя новонародженого», «Дитина перестала дихати
                      з незрозумілих причин. Що ви будете робити?".
                    </div>
                    <br />
                    <div>
                      Лікування варикозу: справжня потреба молодих дівчат -
                      визнання, захоплення. Почуватися впевнено - ось головний
                      біль. Тому в офері потрібно тиснути на це. А не продавати
                      напряму лікування варикозу.
                    </div>
                    <br />
                    <div>Ще кілька прикладів формул для створення оферів:</div>
                    <br />
                    <div>1. Потреба + результат + гарантії;</div>
                    <div>2. ЦА + проблема + рішення;</div>
                    <div>3. Продукт + додаткова цінність;</div>
                    <div>4. Потреба + характеристика товару.</div>
                    <br />
                    <div>
                      Важливо, щоб оффер був конкретним: не "швидка доставка", а
                      "доставка за 15 хвилин".
                    </div>
                    <br />
                    <div>
                      Продавайте результат, а не процес: не "виготовимо кухню за
                      30 днів", а "ви отримаєте кухню за 30 днів".
                    </div>
                    <br />
                    <div>
                      При складанні оферів не забувайте враховувати сходи
                      усвідомлення Бена Ханта. Відштовхуйтеся від того, на якому
                      ступені знаходиться людина.{" "}
                    </div>
                    <br />
                    <div>
                      Якщо він знаходиться на першому ступені байдужості
                      (наприклад, широкі аудиторії, низький попит при низькій
                      конкуренції), то завдання - підштовхнути до усвідомлення
                      проблеми, про яку раніше він не замислювався. Тобто
                      потрібно створити потребу.
                    </div>
                    <div>
                      Якщо ж він усвідомлює свою проблему і шукає шляхи
                      вирішення (знаходиться на ступені обізнаності - за
                      інтересами, за поведінкою), то завдання дати варіанти
                      вирішення його проблеми. У такій рекламі потрібно
                      наголошувати на перевагах, УТП вашого бренду.
                    </div>
                    <div>
                      Також важливо наголошувати на перевагах і закритті
                      заперечень при ретаргетингу, коли людина вже порівнює
                      компанії. При таргетуванні за прямими інтересами людина
                      вже також усвідомлює свою проблему і вже шукає варіанти
                      рішень. У такому разі ваш офер повинен бути кращим, ніж у
                      конкурентів + якісна реклама, щоб вона здалася людині
                      однією з перших.
                    </div>
                    <br />
                    <div>
                      Більше інформації тут{" "}
                      <a href="https://instagram.com/p/CYe9ixnLAgr/">
                        https://instagram.com/p/CYe9ixnLAgr/
                      </a>
                    </div>
                  </>
                ),
              },
            ],
          },
          {
            id: "block9-lesson16",
            label: "Урок 16. Емоції в креативах",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <div>
                      Креативи ще краще будуть працювати, якщо використовувати
                      психологію людини та додавати тезиси, які будуть викликати
                      різний тип емоцій
                    </div>
                    <br />
                    <ul>
                      <li>Страх</li>
                      <li>Цікавість</li>
                      <li>Заздрість</li>
                      <li>Жадібність</li>
                      <li>Почуття провини</li>
                      <li>Марнославство</li>
                      <li>Кохання</li>
                    </ul>
                    <br />
                    <p>
                      <strong>Страх</strong> <br />
                      Люди за своєю природою трохи мазохісти і якщо їм вказати
                      на те, що вони можуть втратити замість придбання – така
                      реклама працюватиме краще. Але тут треба бути обережним у
                      використані, щоб не перестаратись та не визвати негативні
                      емоції до компанії/бренду/експерту.
                    </p>
                    <br />
                    <p>
                      <strong>Цікавість</strong> <br />
                      На цій емоції тримається вся жовта преса. Але потрібно
                      бути уважним з цією емоцією, щоб не зменшити якість
                      реклами за наявність клікбейту.
                    </p>
                    <br />
                    <p>
                      <strong>Заздрість</strong> <br />
                      Заздрять усі, але по-різному. Опишіть більш успішний
                      приклад - що досяг ваш клієнт і чим він краще за
                      потенційного покупця, покажіть результати. Ваше завдання,
                      щоб клієнт сказав «хочу так само»!
                    </p>
                    <br />
                    <p>
                      <strong>Жадібність</strong> <br />
                      Це почуття змушує купувати людей непотрібні речі,
                      наприклад, 3 за ціною 1. Покажіть, що ціна продукту в рази
                      нижче ціни, розкажіть про додаткові вигоди.
                    </p>
                    <br />
                    <p>
                      <strong>Почуття провини</strong> <br />
                      Це почуття змушує відчути людину маленькою дитиною, яка
                      нашкодила. Працює тільки в тому випадку, якщо у рекламі
                      пропонується легке рішення проблеми. Завдання таргетолога
                      – розбудити почуття провини в людині, щоб вона звернулась
                      за допомогою в позитивному вигляді.
                    </p>
                    <br />

                    <p>
                      <strong>Марнославство</strong> <br />
                      Прагнення бути найкращим, підтверджувати своє, перевага,
                      чути від інших гарне про себе. Покажіть, що людина стане
                      особливою з вашим продуктом. Добре використовувати у
                      продажу VIP-продуктів - розкажіть про ексклюзивність та
                      покажіть, як продукт фізично зможе показати оточуючим
                      перевагу над іншими (наприклад, доказ – сертифікат).
                    </p>
                    <br />
                    <p>
                      <strong>Кохання</strong> <br />
                      Пообіцяйте, що продукт змінить особисте життя. Це
                      позитивна емоція, тому працювати з цим набагато легше та
                      використовувати можна в багатьох нішах без школи для
                      бренду/компанії/експерта.
                    </p>
                  </>
                ),
              },
            ],
          },
          {
            id: "block9-lesson18",
            label:
              "Урок 18. Створення рекламного макету для плейсменту рілс (місця які закривають текст)",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <p>
                      <strong>Слова-підсилювачі для емоцій заздрості:</strong>
                    </p>
                    <ul>
                      <li>конкуренти;</li>
                      <li>краще;</li>
                      <li>Попереду;</li>
                      <li>Лідер;</li>
                      <li>Перший;</li>
                      <li>ефективний;</li>
                      <li>Успішний.</li>
                    </ul>

                    <p>
                      <strong>
                        Слова-підсилювачі для емоцій почуття провини:
                      </strong>
                    </p>
                    <ul>
                      <li>повинен;</li>
                      <li>Інші;</li>
                      <li>краще;</li>
                      <li>жаль;</li>
                      <li>Розлад;</li>
                      <li>Очікування;</li>
                      <li>уникнути.</li>
                    </ul>

                    <p>
                      <strong>
                        Слова-підсилювачі для емоцій марнославства:
                      </strong>
                    </p>
                    <ul>
                      <li>схвалення;</li>
                      <li>Репутація;</li>
                      <li>ексклюзив;</li>
                      <li>Престиж;</li>
                      <li>повага;</li>
                      <li>особливий;</li>
                      <li>Захоплення;</li>
                      <li>Ефектний.</li>
                    </ul>
                  </>
                ),
              },
            ],
          },
          {
            id: "block9-lesson18",
            label:
              "Урок 18. Створення рекламного макету для плейсменту рілс (місця які закривають текст)",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <img
                      style={{ maxWidth: 400 }}
                      src="https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D1%84%D0%BE%D1%82%D0%BE%20%D1%82%D0%B5%D0%BB%D0%B5%D1%84%D0%BE%D0%BD%D0%B0%20%D1%80%D0%B0%D0%B7%D0%BC%D0%B5%D1%80%D0%B8.jpeg?alt=media&token=44e5ef6b-b5d7-4a80-b4c3-b881ab8c201e"
                    />
                  </>
                ),
              },
            ],
          },
          {
            id: "block9-lesson19",
            label: "Урок 19. Що таке тригери та навіщо вони в креативах",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D1%82%D1%80%D0%B8%D0%B3%D0%B5%D1%80%D0%B8.MP4?alt=media&token=c2ac5215-37a4-477e-b08a-62b48b1917ba",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%A2%D1%80%D0%B8%D0%B3%D0%B5%D1%80%D0%B81.docx?alt=media&token=81db80a0-eeda-470f-99dc-cd925ac580ee",
                name: "Тригери файл",
              },
            ],
          },
          {
            id: "block9-lesson20",
            label:
              "Урок 20. Варіант написання ТЗ дизайнеру для замовлення креативів",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%209%2F%D0%A8%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD_%D0%A2%D0%97_%D0%B4%D0%BB%D1%8F_%D0%B4%D0%B8%D0%B7%D0%B0%D0%B8%CC%86%D0%BD%D0%B5%D1%80%D0%B0_%D0%BD%D0%B0_%D1%80%D0%BE%D0%B7%D1%80%D0%BE%D0%B1%D0%BA%D1%83_%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D1%96%D0%B2.docx?alt=media&token=b7a4381c-2a85-4aca-9fee-91d5af5c2c1a",
                name: "Шаблон ТЗ для дизайнера",
              },
            ],
          },
          {
            id: "block9-lesson21",
            label:
              "Урок 21. ПОМИЛКИ, при створенні креативів або як НЕ треба робити ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <p>
                      - багато тексту
                      <br />
                      - неякісне фото/відео
                      <br />
                      - помилки у тексті
                      <br />
                      - немає візуально-продуманої концепції
                      <br />
                      - нерозуміння цілі даного креативу
                      <br />
                      - невідповідність креативу до продажу товарів/послуг
                      <br />
                      - порушення правил (ознайомитись з усіма правилами ви
                      можете в СПРАВЦІ ВІД ФБ)
                      <br />
                      - не підганяємо розмір креативу під плейсменти
                      <br />
                      - повільне відео/анімація
                      <br />- нерозуміння ца вигоди з вашим товаром/продуктом
                    </p>
                  </>
                ),
              },
            ],
          },
          {
            id: "block9-lesson22",
            label: "Урок 22. ДЗ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            homework: true,
            info: [
              {
                title:
                  "Створити декілька варіантів креативів для своїх клієнтів та відправити на розбір",
                content: (
                  <>
                    *якщо клієнтів немає - обирає декілька різних ніш і пробуємо
                    зробити для них
                    <br />* якщо ви таргетолог з досвідом - висилайте свої
                    креативи на розбір
                  </>
                ),
              },
            ],
          },
        ],
      },
      {
        id: "block10",
        label: "Модуль 10. Створення рекламних текстів",
        description:
          "Навчишся писати продаючі тексти під будь-яку нішу та ціль",
        disabled: false,
        speaker: "Запрошений спікер Данило Роговий",
        children: [
          {
            id: "block10-lesson1",
            label: "Урок 1. Вступ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content:
                  "На даному модулі ти навчишся писати продаючі тексти під будь-яку нішу та ціль",
              },
            ],
          },
          {
            id: "block10-lesson2",
            label: "Урок 2. Основи копірайтингу",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2010%2F%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%B8%20%D0%BA%D0%BE%D0%BF%D1%96%D1%80%D0%B0%D0%B8%CC%86%D1%82%D0%B8%D0%BD%D0%B3%D1%83.MP4?alt=media&token=677ea488-4c0f-4876-b7fd-d3bf2e933034",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2010%2F%D0%9E%D1%81%D0%BD%D0%BE%D0%B2%D0%B8%20%D0%BA%D0%BE%D0%BF%D1%96%D1%80%D0%B0%D0%B8%CC%86%D1%82%D0%B8%D0%BD%D0%B3%D1%83%20(1).pdf?alt=media&token=e03c8ea1-7c4e-4c25-a19c-6a933b77a361",
                name: "Основи копірайтингу",
              },
            ],
          },
          {
            id: "block10-lesson3",
            label: "Урок 3. Як писати рекламні тексти на які будуть реагувати",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2010%2F%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%B8%20%D0%BA%D0%BE%D0%BF%D1%96%D1%80%D0%B0%D0%B8%CC%86%D1%82%D0%B8%D0%BD%D0%B3%D1%83.MP4?alt=media&token=677ea488-4c0f-4876-b7fd-d3bf2e933034",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2010%2F%D0%A1%D1%82%D0%B2%D0%BE%D1%80%D0%B5%D0%BD%D0%BD%D1%8F%20%D1%82%D0%B5%D0%BA%D1%81%D1%82%D1%83%20(1).pdf?alt=media&token=b9a07229-3f15-4514-ac74-a8450a1f0ec9",
                name: "Створення тексту",
              },
            ],
          },
          {
            id: "block10-lesson4",
            label: "Урок 4. Приклади текстів для реклами",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <h3>Заголовки</h3>
                    <p>
                      - Хочеш навчитися танцювати за 30 днів? Тоді онлайн-курс
                      «......» точно для тебе!
                    </p>
                    <p>
                      - Хочеш круто танцювати, але не знаєш з чого почати?
                      Реєструйся на курс "....."!
                    </p>
                    <p>
                      - Думаєш, як розпочати навчання по танцям з 0? Тисні
                      «детальніше» і запишись на пробне заняття онлайн-курсу
                      «......»
                    </p>
                    <p>
                      - 10 уроків танців, після яких ти закохаєшся заново!
                      Реєструйся на безкоштовне заняття прямо зараз!
                    </p>
                    <h3>Головний текст</h3>
                    <p>Після реєстрації на курс, ти отримаєш:</p>
                    <ul>
                      <li>
                        10 основних тренувань по 45 хвилин, кожне з яких
                        спрямоване на розвиток тієї чи іншої частини тіла.
                      </li>
                      <li>
                        Основні поради щодо правильного харчування для схуднення
                        та підтримки гарної форми.
                      </li>
                      <li>
                        На кожному уроці, після опрацювання базових елементів,
                        ти вивчиш круту хореографію, щоб закріпити свої навички.
                      </li>
                      <li>
                        Імпровізація. На цьому тренуванні ти навчишся
                        комбінувати вивчені рухи і відчувати себе більш вільно в
                        танці.
                      </li>
                      <li>
                        БОНУС. Вогняне тренування на прокачування ідеального
                        преса.
                      </li>
                    </ul>

                    <p>
                      Тренер курсу – Юлія Бакуленко 15 років розвитку у танцях
                      та співпраця з понад 15 ТОП-зірками українського
                      Шоу-бізнесу!
                    </p>

                    <h3>ОФЕР</h3>
                    <ul>
                      <li>
                        Тільки до 25 травня, не пропусти можливість пройти 1
                        урок безкоштовно!
                      </li>
                      <li>
                        Пройди перший урок абсолютно безкоштовно, тільки до 25
                        травня!
                      </li>
                      <li>
                        Відкриваємо безкоштовний доступ до 1 уроку лише для
                        перших 50 осіб!
                      </li>
                    </ul>

                    <h3>Заклики</h3>
                    <p>
                      Жми «Докладніше», переходь за посиланням та навчися
                      танцювати всього за 30 днів!
                    </p>
                    <p>
                      Жми «Докладніше» щоб дізнатися повну програму онлайн-курсу
                      та зареєструватись на пробний урок!
                    </p>
                    <p>
                      Вже хочеш почати? Тисни «Докладніше» та реєструйся на
                      безкоштовний 1 урок!
                    </p>

                    <p>
                      <strong>ПРИМІТКА**</strong> - всі блоки з позначкою «*»
                      будуть змінюватись у текстах рк.
                    </p>
                    <h3>ПРИКЛАД ТЕКСТУ ДЛЯ НІШІ "ПРОДАЖ АВТО" 👇</h3>
                    <h3>(1 варіант)</h3>
                    <p>
                      Ти плануєш купити авто? Залиш заявку та дізнайся як
                      заощадити на покупці авто з США та Європи!
                    </p>
                    <p>
                      Вже понад 7 років ми доставляємо авто «під ключ» і в
                      найкоротший термін!
                    </p>

                    <p>Про нас:</p>
                    <ul>
                      <li>Викуповуємо понад 40 машин щомісяця</li>
                      <li>
                        Вже через 7 днів, ви отримаєте розмитнене та
                        сертифіковане авто!
                      </li>
                      <li>
                        В наявності своє СТО, що дозволяє гарантувати авто «під
                        ключ» з якісним ремонтом
                      </li>
                      <li>Допомагаємо заощадити на покупці авто до 30-40%</li>
                      <li>Викуповуємо авто тільки з оригінальним пробігом</li>
                    </ul>

                    <p>
                      Бажаєте придбати якісне авто за найкращою ціною? Тисніть
                      «детальніше» та залишайте заявку прямо зараз!
                    </p>

                    <h3>(2 варіант)</h3>
                    <p>
                      Доставимо авто «ПІД КЛЮЧ» із США або ЄВРОПИ всього за 7
                      днів!
                    </p>
                    <p>Залишай заявку прямо зараз щоб дізнатися деталі!</p>

                    <p>Переваги замовлення авто у нас:</p>
                    <ul>
                      <li>Досвід понад 7 років!</li>
                      <li>Понад 1000 підтверджених авто</li>
                      <li>Викуповуємо від 40 авто на місяць</li>
                      <li>
                        Наявність особистого СТО для гарантії якісного ремонту
                      </li>
                      <li>Найкоротші терміни доставки</li>
                      <li>Реєструємо та Сертифікуємо авто</li>
                      <li>Усі авто тільки з оригінальним пробігом</li>
                    </ul>

                    <p>
                      Бажаєте придбати авто у кращому співвідношенні
                      ціна/якість? Тисніть «детальніше» та залишайте заявку
                      прямо зараз!
                    </p>

                    <h3>ПРИКЛАД ТЕКСТУ ДЛЯ НІШІ "ЖІНОЧИЙ ОДЯГ" 👇</h3>
                    <h3>(1 варіант)</h3>
                    <p>Зустрічай осінь комфортно та красиво!</p>
                    <p>
                      Лаконічні, жіночні та шалено стильні вбрання з авторськими
                      принтами та неймовірними фасонами вже чекають на тебе в
                      "назва магазину"
                    </p>
                    <ul>
                      <li>
                        Весь одяг виконаний виключно з якісних дорогих тканин і
                        служить не один сезон
                      </li>
                      <li>Обмін/Повернення та гарантія якості</li>
                      <li>Доставка в будь-яку точку світу</li>
                      <li>
                        Є гарантія і онлайн підтримка покупця після покупки
                      </li>
                      <li>Всі образи можна купити онлайн з будь-якої точки</li>
                    </ul>
                    <p>
                      А завдяки фірмовій упаковці отримаєте ще й естетичне
                      задоволення.
                    </p>
                    <p>
                      Переходь і підписуйся (сторінка магазину) Тут ще більше
                      фото нарядів для тебе!
                    </p>

                    <h3>(2 варіант)</h3>
                    <p>
                      Для виходу у світ на захід чи урочистість, на ділову
                      зустріч чи на чашку кави з кращою подругою. Наш брючний
                      костюм буде доречний завжди!
                    </p>
                    <p>Поза будь-яким дрес-кодом і часу</p>
                    <ul>
                      <li>
                        Весь одяг виконаний виключно з якісних дорогих тканин і
                        служить не один сезон
                      </li>
                      <li>Обмін/Повернення та гарантія якості</li>
                      <li>Доставка в будь-яку точку світу</li>
                      <li>
                        Є гарантія і онлайн підтримка покупця після покупки
                      </li>
                      <li>Всі образи можна купити онлайн з будь-якої точки</li>
                    </ul>
                    <p>
                      А завдяки фірмовій упаковці отримаєте ще й естетичне
                      задоволення.
                    </p>
                    <p>
                      Переходь і підписуйся (сторінка магазину) Тут ще більше
                      фото нарядів для тебе!
                    </p>

                    <h3>(3 варіант)</h3>
                    <p>Встигни замовити! Тільки до 10 квітня Знижка -50%</p>
                    <p>Стара ціна – 2995 грн.</p>
                    <p>Нова ціна – 1495 грн.</p>
                    <p>Технологічна куртка "Racer". Демісезонна модель;</p>
                    <p>Захист від вологи та вітру, з капюшоном</p>
                    <p>
                      Завдяки технології "water resistance" та "wind stopper"
                      куртка не промокне і не пропускає холод. Голову від вітру
                      захистить каптур, який легко ховається у комір під
                      блискавку.
                    </p>
                    <p>
                      Завдяки особливому пошиттю, куртка ідеально підійде для
                      активних прогулянок та важливих зустрічей.
                    </p>
                    <p>
                      Бажаєш -50% на куртку? Став «+» у коментарях або пиши до
                      Директу
                    </p>

                    <h3>(4 варіант)</h3>
                    <p>SALE! Встигни замовити зі Знижкою -50%!</p>
                    <p>
                      Одна з найзручніших демісезонних моделей, відмінно
                      підходить для активних прогулянок містом та ділових
                      зустрічей. Поспішайте, кількість обмежена!
                    </p>
                    <p>Модель:</p>
                    <ul>
                      <li>Демісезонна</li>
                      <li>Непромокальна</li>
                      <li>З капюшоном</li>
                    </ul>
                    <p>Виконана за технологіями:</p>
                    <ul>
                      <li>«water resistance» - захист від дощу та вологи</li>
                      <li>"wind stopper" - захист від вітру</li>
                      <li>«avtodriver» - особливе пошиття для свободи рухів</li>
                    </ul>
                    <p>
                      Бажаєш -50% на куртку? Став «+» у коментарях або пиши до
                      Директу
                    </p>
                    <h3>ПРИКЛАД ТЕКСТУ ДЛЯ НІШІ "СПОРТ" 👇</h3>

                    <p>
                      Вперше! Онлайн – семінар від ТОП тренера .....! (ім'я
                      тренерів будемо міняти за ЦА)
                    </p>
                    <p>
                      Ти цікавишся навчанням у сфері футболу? Не проґав свій
                      шанс відчути в семінарі і отримати ексклюзивні знання від
                      тренера Кріштіану Роналду!
                    </p>
                    <ul>
                      <li>Реальні кейси роботи професіоналів</li>
                      <li>
                        Рекомендації та фішки роботи з цільовою аудиторією
                      </li>
                      <li>Отримання онлайн сертифіката</li>
                    </ul>
                    <p>
                      Головна тема семінару - «Тренування на швидкість та
                      мотивація найкращих футболістів світу» (тему змінюємо
                      відповідно до ЦА)
                    </p>
                    <p>
                      Семінар буде корисним для: футболістів, футбольних
                      тренерів, менеджерів клубів (Міняємо під ЦА)
                    </p>
                    <p>
                      Бажаєте отримати ексклюзивні знання від ТОП тренера?
                      Тисніть «детальніше» та реєструйтесь прямо зараз!
                    </p>

                    <h3>ПРИКЛАД ТЕКСТУ ДЛЯ НІШІ "ПЕРМАНЕНТНИЙ МАКІЯЖ" 👇</h3>

                    <p>
                      Отримай затребувану професію за 5 днів - стань майстром
                      перманентного макіяжу!
                    </p>
                    <p>
                      Кайфуй від жіночої енергії та заробляй на цьому від 1000$
                    </p>
                    <p>На навчанні:</p>
                    <ul>
                      <li>
                        Без зайвої води ти дізнаєшся про всі тонкощі та секрети
                        майстрів перманентного макіяжу
                      </li>
                      <li>Отримаєш багато практики</li>
                      <li>
                        І найголовніше - станеш готовим фахівцем з усім
                        необхідним багажем знань 👩🏼‍🎓
                      </li>
                    </ul>
                    <p>Чому ми?</p>
                    <ul>
                      <li>
                        Викладає ТОП майстрам з великим практичним досвідом в
                        Україні та Туреччині 🌏
                      </li>
                      <li>Велика кількість позитивних відгуків студентів✔️</li>
                      <li>Наші випускники вже успішно працюють у сфері 👩🏼‍💻</li>
                      <li>Підтримка після закінчення курсу✔️</li>
                      <li>
                        Тільки у нас - основи ведення свого бізнесу та багато
                        іншого 📲
                      </li>
                    </ul>
                    <p>
                      Пиши в дірект "навчання", щоб встугнути забронювати своє
                      місце по найвигіднішій ціні!
                    </p>
                    <p>кількість місць обмежена</p>
                  </>
                ),
              },
            ],
          },
          {
            id: "block10-lesson5",
            label:
              "Урок 5. Приклади формул, які можна використовувати в текстах та креативах",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,

            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2010%2F%D0%A4%D0%BE%D1%80%D0%BC%D1%83%D0%BB%D0%B8%20(%D0%BF%D1%80%D0%B8%D0%BA%D0%BB%D0%B0%D0%B4%D0%B8).docx?alt=media&token=92737609-617c-4a96-b63c-fabc7bfe0028",
                name: "Формули (приклади)",
              },
            ],
          },
          {
            id: "block10-lesson6",
            label: "Урок 6. ДЗ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            homework: true,
            info: [
              {
                content: (
                  <>Обрати 2 ніші та підготувати креативи та тексти під них</>
                ),
              },
            ],
          },
        ],
      },
      {
        id: "block11",
        label: "Модуль 11. Аналітика та звіти",
        description:
          "Ти дізнаєшся всі можливі методи пошуку клієнтів, їх закриття та як з ними працюват - що просто НЕРЕАЛЬНО буде піти з навчання без клієнтів",
        disabled: false,
        children: [
          {
            id: "block11-lesson1",
            label: "Урок 1. Вступ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content:
                  "Ти отримаєш чітке розуміння як правильно аналізувати результати реклами, щоб давати результат в будь-якому проекті",
              },
            ],
          },
          {
            id: "block11-lesson2",
            label:
              "Урок 2. Навіщо робити аналітику? Які дані варто аналізувати і як часто?",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2011%2F%D0%B0%D0%BD%D0%B0%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%B2%20%D1%80%D0%BA.mov?alt=media&token=e5e274f2-db0e-4099-af50-be2fd759bb7f",
              },
            ],
          },
          {
            id: "block11-lesson3",
            label: "Урок 3. Аналітика рекламних компаній",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <div>
                      Комплексна аналітика дозволить оцінити, наскільки
                      правильно було обрано прийоми, якою виявилася стратегія:
                      прибутковою чи збитковою. У результаті ви зможете
                      виключити малоефективну рекламу та направити бюджет на ті
                      методи просування, які залучать більше потенційних
                      клієнтів.
                    </div>
                    <br />
                    <div>
                      Реклама повинна не просто показуватись вашій цільовій
                      аудиторії. Вона має бути ефективною – залучати потенційних
                      клієнтів, просувати товар і згодом приносити прибуток. Уже
                      через місяць можна сказати, наскільки вдалою виявилася
                      стратегія. І щоб звіт з реклами був об'єктивним, потрібний
                      докладний аналіз. Аналітика рекламних кампаній дозволяє
                      відстежити та виправити допущені в процесі просування
                      бренду помилки.{" "}
                    </div>
                    <br />
                    <strong>Регулярний плановий аналіз</strong>
                    <br />
                    <div>
                      Періодичні перевірки дозволяють швидше відстежити помилки
                      та виправити їх, поки у просування не було вкладено
                      великих сум.
                    </div>
                    <br />
                    <strong>Місячні результати </strong>
                    <br />
                    <div>
                      Наприкінці кожного місяцю складається докладний звіт
                      діяльності й успіхів компанії. Це стосується й аналізу її
                      просування на ринку. Для оцінки враховують такі критерії:
                    </div>

                    <ul>
                      <li>кількість лідів;</li>
                      <li>рівень продажу;</li>
                      <li>вартість лідів;</li>
                      <li>бюджет реклами та її рентабельність.</li>
                    </ul>
                    <br />
                    <strong>Зміни стратегії просування</strong>
                    <br />
                    <div>
                      Якщо ви вирішуєте змінити стратегію – бренд розширився,
                      змінив перелік товарів/послуг, доповнив концепцію –
                      потрібно позапланово проаналізувати попередню рекламну
                      кампанію:
                    </div>
                    <ul>
                      <li>цільову спрямованість – особливості вашої ЦА;</li>
                      <li>метрики;</li>
                      <li>ефективні креативні рішення;</li>
                      <li>
                        ті елементи концепції, які залишаються актуальними,
                        важливо зрозуміти, чи були вони прибутковими й
                        успішними.
                      </li>
                    </ul>
                    <br />
                    <div>
                      Сюди можна додати причину аналізу рекламної кампанії –
                      зміна підрядника, відповідального за просування. У такому
                      разі фахівець зобов'язаний перевірити, наскільки
                      ефективною була попередня стратегія, щоб на її основі
                      розробляти нові.
                    </div>
                    <br />
                    <div>
                      Окремо слід назвати аналіз рекламної кампанії ваших прямих
                      конкурентів. Він дозволяє визначити їх сильні та слабкі
                      сторони, знайти помилки й уникнути їх, скориставшись чужим
                      прикладом. А ось вдалими прикладами можна надихнутись і
                      переосмислити (але в жодному разі не копіювати – клієнти
                      швидко розпізнають плагіат) ідею.
                    </div>
                    <br />
                    <strong>Показники, які слід брати до уваги </strong>
                    <br />
                    <div>
                      Під час аналізу рекламної кампанії відштовхуйтеся від
                      того, на яких майданчиках ви просуваєте власний бренд.
                      Якщо це соціальні мережі, потрібно дивитися на:
                    </div>
                    <ul>
                      <li>охоплення;</li>
                      <li>залучення аудиторії (ER);</li>
                      <li>динаміку збільшення аудиторії;</li>
                      <li>кількість переходів за рекламними посиланнями;</li>
                      <li>кількість і вартість лідів тощо.</li>
                    </ul>
                    <br />
                    <div>
                      Якщо просування сайту спрямоване головним чином на видачу
                      в пошуковій системі, ключовими об'єктами аналізу стануть:
                    </div>
                    <ul>
                      <li>кількість переходів з рекламного оголошення;</li>
                      <li>вартість показів;</li>
                      <li>кількість показів;</li>
                      <li>показники відмов.</li>
                    </ul>
                    <br />
                    <div>
                      Загальними важливими параметрами для всіх видів
                      онлайн-просування є: глибина перегляду (тривалість
                      відвідування сайту або сторінки), вартість одного переходу
                      за рекламним посиланням, вартість цільового
                      клієнта/продажу/запиту.
                    </div>
                  </>
                ),
              },
            ],
          },
          {
            id: "block11-lesson4",
            label: "Урок 4. Метрики в рекламному кабінеті",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <div>
                      <strong>Налаштування атрибуції</strong> – це обмежений
                      період часу, протягом якого конверсії присвоюються вашим
                      оголошенням; також використовується для оптимізації
                      кампанії. Наприклад, якщо вибрати значення «7 днів після
                      кліку», наша система буде враховувати під час навчання
                      конверсії, які відбуваються протягом 7 днів і показувати
                      рекламу тим, хто з найбільшою ймовірністю виконає
                      конверсію за цей час. Для звітності можна вибирати кілька
                      налаштувань атрибуції.
                    </div>
                    <br />
                    <div>
                      <strong>Результати</strong> - це кількість разів, коли
                      ваша реклама досягала результату, визначеного вашою ціллю
                      та налаштуваннями.
                    </div>
                    <br />
                    <div>
                      <strong>Охоплення</strong> - це кількість облікових
                      записів, які бачили вашу рекламу принаймні один раз.
                      Відмінність охоплення від показів полягає в тому, що
                      останні можуть включати багаторазові перегляди реклами
                      тими самими обліковими записами.
                    </div>
                    <br />
                    <div>
                      <strong>Частота </strong>- це середня кількість переглядів
                      реклами 1 користувачем.
                    </div>
                    <br />
                    <div>
                      <strong>Ціна за результат</strong> - середня ціна за
                      результат, який принесла реклама.
                    </div>
                    <br />
                    <div>
                      <strong>Бюджет</strong> - максимальна сума, яку ви хочете
                      витратити на набори реклами або кампанії, у середньому за
                      день або за весь час показу запланованих вами наборів
                      реклами або кампаній.
                    </div>
                    <br />
                    <div>
                      <strong>
                        Цей стовпчик не застосовується до ads, а тільки до
                        набори реклами та кампанії.
                      </strong>
                    </div>
                    <br />
                    <div>
                      <strong>Сума витрат</strong> - приблизна загальна
                      кількість грошей, які ви витратили на свою кампанію, набір
                      реклами або оголошення впродовж їхнього показу.
                    </div>
                    <br />
                    <div>
                      <strong>Завершення</strong> - запланована дата завершення
                      показу кампанії.
                    </div>
                    <br />
                    <div>
                      <strong>Покази</strong> - кількість показів реклами на
                      екрані.
                    </div>
                    <br />
                    <div>
                      <strong>CPM (ціна за 1000 показів)</strong> - середня ціна
                      за 1000 показів.
                    </div>
                    <br />
                    <div>
                      <strong>Кліки посилання</strong> - кількість кліків
                      посилань в оголошенні, які спрямували користувачів на
                      зазначені рекламодавцем місця призначення в межах Meta чи
                      поза ними.
                    </div>
                    <br />
                    <div>
                      <strong>CPC (ціна за клік посилання)</strong> - середня
                      ціна кожного кліку посилання.
                    </div>
                    <br />
                    <div>
                      <strong>CTR (рейтинг кліків)</strong> - відсоткова частка
                      показів, під час яких відбувся клік посилання, від
                      загальної кількості показів. Показує наскільки
                      клікабельний макет.
                    </div>
                    <br />
                    <div>
                      <strong>Кліки (усі)</strong> - кількість кліків, дотиків
                      або проведень пальцем по вашій рекламі.
                    </div>
                    <br />
                    <div>
                      <strong>CTR (усі)</strong> - відсоткова частка показів,
                      під час яких відбувся клік (усі), від загальної кількості
                      показів. Показує наскільки клікабельний макет у
                      співвідношенні %.
                    </div>
                    <br />
                    <div>
                      <strong>Ціна за клік (всі)</strong> - середня ціна за
                      кожний клік (всі).
                    </div>
                  </>
                ),
              },
            ],
          },
          {
            id: "block11-lesson5",
            label: `Урок 5. Давайте розберемо "складні" терміни простою мовою. Формули, які допоможуть вам краще прораховувати прибутковість проєктів `,
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <div>
                      <strong>ROAS, ROMI, ROI</strong> – ви можете зустрічати це
                      часто у звітах та контексті інших фахівців. Давайте
                      поговоримо про кожного, щоб ви змогли розуміти, що це для
                      чого і як вважається.
                    </div>
                    <br />• <strong>ROAS (return on advertising spend)</strong>{" "}
                    – це окупність витрат на рекламу. Вважати можна в % (але я
                    вважаю без, мені і клієнтам так зручніше)
                    <br />
                    <br />
                    Формула: ROAS = дохід від реклами / витрати на рекламу
                    <br />
                    <br />
                    Наприклад: На рекламу ви витратили 500 $, а заробили 1000 $
                    (не чистими). Значить 1000/500 = 2, виходить на 1 $
                    витрачений, реклама принесла 2 $. Чим вище ROAS - тим краще
                    <br /> <br />•
                    <strong>ROMI (Return on Marketing Investment)</strong> –
                    коефіцієнт окупності вкладень у маркетинг. ROMI враховує
                    лише маркетингові витрати та не включає витрати на
                    виробництво товару, зарплати співробітників, оренду
                    приміщення.
                    <br /> <br />
                    Формула: ROMI = (доходи від маркетингу - Витрати маркетинг)
                    / Витрати маркетинг х 100%
                    <br /> <br />• <strong>ROI (return on investment)</strong> –
                    це коефіцієнт окупності чи показник повернення всіх
                    інвестицій. На відміну від показника ROMI, який враховує
                    лише маркетингові витрати, ROI допомагає визначити,
                    наскільки вигідним є весь проект з урахуванням усіх вкладень
                    у нього. Щоб визначити ROI, необхідно врахувати всі витрати
                    на проект та прибутки з нього.
                    <br /> <br />
                    Формула: ROI = (дохід-витрати) / витрати x100%
                  </>
                ),
              },
            ],
          },
          {
            id: "block11-lesson6",
            label: "Урок 6. Види звітів. Розбір на прикладах",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2011%2F%D0%B2%D0%B8%D0%B4%D0%B8%20%D0%B7%D0%B2%D1%96%D1%82%D1%96%D0%B2.mov?alt=media&token=641173a0-4b8e-4777-ab35-c4464a8bd395",
                text: (
                  <>
                    <a
                      href="https://docs.google.com/spreadsheets/d/17Q4yLEY-4h8kJgGb21zbDMmZSF4_FmBn41jmczrYrXw/edit?usp=sharing"
                      target="_blank"
                    >
                      Приклад звіту
                    </a>
                  </>
                ),
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2011%2F%D0%97%D0%B2%D1%96%D1%82%20(%D0%BF%D1%80%D0%B8%D0%BA%D0%BB%D0%B0%D0%B4).docx?alt=media&token=c214c4ab-35c3-4f75-a15f-e92af2e5cd48",
                name: "Звіт (приклад) ",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2011%2F%D0%9E%D1%82%D1%87%D0%B5%D1%82%20tenze%20(03.04-03.05).pdf?alt=media&token=385fc105-29d5-4402-881a-e1a3e2f05ba3",
                name: "Звіт тензе",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2011%2F%D0%97%D0%B2%D1%96%D1%82%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%B8%20%D0%B4%D0%BB%D1%8F%20barleyshop%2016.09.23%20%20(1).pages?alt=media&token=f8bdd1b0-9cd9-4718-88e8-5314a2b63c35",
                name: "Звіт реклами для barleyshop",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2011%2F%D0%B0%D0%BD%D0%B0%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%B7%D0%B0%D0%BF%D1%83%D1%81%D0%BA%D0%B0%20%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D1%8B%20(1).pdf?alt=media&token=a0e6537a-ec6f-4f93-96a0-fdc04e10ce04",
                name: "Аналітика запуску реклами",
              },
            ],
          },
          {
            id: "block11-lesson7",
            label:
              "Урок 7. Де ще можна брати данні для звітів та аналізувати проект",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <div>
                      <h3>
                        Де ще можна брати данні для звітів та аналізувати
                        проект:
                      </h3>
                      <strong>Meta business suite</strong> - переходимо в розділ
                      статистика та аналізуемо необхідні метрики
                      <img
                        width="600"
                        src="https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2011%2F%D1%84%D0%BE%D1%82%D0%BE1.jpeg?alt=media&token=b7ad617c-0a09-4471-b911-fe009867cba5"
                        alt="instruction"
                      />
                    </div>
                    <h3>
                      Де ще можна брати данні для звітів та аналізувати проект:
                    </h3>
                    <strong>В рекламному кабінеті </strong> - переходимо у
                    вкладку "звіти" та обираємо необхідні метрики для аналізу
                    <img
                      width="600"
                      src="https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2011%2F%D1%84%D0%BE%D1%82%D0%BE2.jpeg?alt=media&token=8de76fd1-e1ae-4756-a31a-e992b6ddf999"
                      alt="instruction 2"
                    />
                  </>
                ),
              },
            ],
          },
          {
            id: "block11-lesson8",
            label:
              "Урок 8. Аналіз реклами попереднього таргетолога перед стартом роботи з клієнтом",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <div>
                      {" "}
                      Коли ви починаєте працювати з клієнтами після інших
                      спеціалістів - одна з основних задач на початку -
                      <strong>ПРОАНАЛІЗУВАТИ ПОПЕРЕДНІ ЗАПУСКИ.</strong>
                      <br />
                      Це робиться для того, щоб розуміти, що вже тестували та
                      який це мало результат (можливо щось йшло дуже добре і ми
                      можемо спробувати запустити так само, щоб отримувати
                      потенційних клієнтів; або ж навпаки - як не треба
                      запускати бо воно не працює)
                    </div>
                    <br />{" "}
                    <div>
                      {" "}
                      1. Аналізуємо розбивки – стать, вік, регіон, плейсмент і
                      тд. Обов'язково дивимося не лише на клікабельність, а й на
                      суму витрат та CPM. Якщо ви бачите, що на якийсь пласт
                      були мінімальні витрати, то Facebook визначив іншого
                      переможця (інший регіон, вік чи стать) і віддає йому
                      більший бюджет. Це не означає, що інший пласт працюватиме
                      краще. Просто його не мали можливості протестувати. Але
                      якщо ви бачите відверто погані результати з великими
                      сумами витрат (наприклад, дорогі ціни заявок, реєстрацій)
                      + клієнт каже, що ці аудиторії не купують, візьміть на
                      замітку і не запускайте такі аудиторії.
                    </div>
                    <br />
                    <div>
                      {" "}
                      2. Аналізуємо середні результати за лід, заявку, клік від
                      попереднього таргетолога. Дивимося середні показники CTR
                      та CPM. Наша мета – зрозуміти, як зробити ще краще. Для
                      цього запитуємо, якої якості ліди приходили, чи
                      задоволений був клієнт роботою.
                    </div>
                    <br />
                    <div>
                      ❗️Не завжди низька ціна за конверсію = низька ціна
                      покупки. Часто трапляється, що ліди коштують дорого, але
                      конвертуються набагато краще. Наша мета, як таргетолога,
                      давати добрий кінцевий результат. Клієнту не важливо,
                      скільки коштує клік чи заявка. Йому потрібні якісні ліди
                      та продажі. Ефективність роботи вимірюється прибутком
                      клієнта.
                    </div>
                    <br />
                    <div>
                      {" "}
                      3. Виписуємо, які аудиторії та плейсменти
                      використовувалися. Чи були задіяні схожі аудиторії та
                      ретаргетинг.
                    </div>
                    <br />
                    <div>
                      {" "}
                      4. Аналізуємо показник відмов. (Якщо реклама була на сайт
                      - то аналізуємо через Google Analytics чи в CRM-системі;
                      якщо без сайту то питаємо у клієнта) і намагаємося
                      зрозуміти, чому так (клікбейт, довге завантаження сайту,
                      низька якість сайту, висока ціна, не обробляли заперечення
                      і тд). Також дивимося на час проведений на сторінці/сайті
                      та глибину переглядів - високі показники говорять про те,
                      що аудиторія з реклами зацікавлена.
                    </div>
                    <br />
                    <div>
                      {" "}
                      5. Дивимося на креативи та оффери. Аналізуємо кількість
                      результатів щодо них та клікабельність. Беремо на замітку
                      найефективніші та найнеефективніші. Не забуваймо, що для
                      чесної аналітики важливо дивитися на суму витрат! Може
                      бути 1 охоплення і висока клікабельність, але в міру
                      октрутки бюджету вона може спуститися нижче 1%. Якщо
                      реклама відразу після запуску отримує клік, звичайно, вона
                      матиме високий відсоток СTR. Тому слід дивитися на всі
                      показники.
                    </div>
                  </>
                ),
              },
            ],
          },
          {
            id: "block11-lesson9",
            label: "Урок 9. Реклама не дає результату, що робити?",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2011%2F%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%B0%20%D0%BD%D0%B5%20%D0%BF%D1%80%D0%B0%D1%86%D1%8E%D1%8D.mov?alt=media&token=7f515eec-ca1c-4994-904e-dbcb65a36537",
              },
            ],
          },
          {
            id: "block11-lesson10",
            label: "Урок 10. ДЗ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            homework: true,
            info: [
              {
                content:
                  "Зробити звіт та вислати на перевірку (ви можете зробити для клієнтів, з якими працюєте/працювали; або ж створити шаблон для використання)",
              },
            ],
          },
        ],
      },
      {
        id: "block12",
        label: "Модуль 12. ПРОДАЖІ",
        description:
          "Ти отримаєш чітке розуміння як правильно аналізувати результати реклами, щоб давати результат в будь-якому проекті",
        disabled: false,
        children: [
          {
            id: "block12-lesson1",
            label: "Урок 1. Вступ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    Після ти зможеш зробити клієнту ВАУ-ефект, адже даватимеш не
                    тільки заявки, а продажі, після чого
                    <br />- ЦЕЙ КЛІЄНТ З ТОБОЮ НАЗАВЖДИ!
                  </>
                ),
              },
            ],
          },
          {
            id: "block12-lesson2",
            label: "Урок 2.Створення індивідуальних постів",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2012%2Fc%D1%82%D0%B2%D0%BE%D1%80%D0%B5%D0%BD%D0%BD%D1%8F%20%D1%96%D0%BD%D0%B4%D0%B8%D0%B2%D1%96%D0%B4%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D0%B8%D1%85%20%D1%81%D0%BA%D1%80%D0%B8%D0%BF%D1%82%D1%96%D0%B2.MP4?alt=media&token=79b6306a-78f3-418a-94f4-7894a72f213d",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2012%2F%D0%A1%D1%82%D0%B2%D0%BE%D1%80%D0%B5%D0%BD%D0%BD%D1%8F_%D1%96%D0%BD%D0%B4%D0%B8%D0%B2%D1%96%D0%B4%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D0%B8%D1%85_%D1%81%D0%BA%D1%80%D0%B8%D0%BF%D1%82%D1%96%D0%B2_2.pdf?alt=media&token=353e1274-ac3d-47b8-9ab5-913b7e1c7527",
                name: "Створення індивідуальних скрипів",
              },
            ],
          },
          {
            id: "block12-lesson3",
            label: "Урок 3. Приклад скриптів продажів під різні ніші",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2012%2F%D0%A1%D0%BA%D1%80%D0%B8%D0%BF%D1%82%D0%B8%20%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%B6%D1%96%D0%B2.xmind?alt=media&token=67c35e94-1ec6-4646-a21b-18054000f723",
                name: "Скрипти продажів.xmind",
              },
            ],
            info: [
              {
                content: (
                  <>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2012%2F%D1%81%D0%BA%D1%80%D0%B8%D0%BF%D1%82%20%D1%81%D0%BA%D1%80%D0%B8%D0%BF%D1%82%20%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%B6%D1%96%D0%B2.png?alt=media&token=d56b15b0-e39c-4395-a77a-04d3c7606cc1"
                      alt="скріншот"
                    />
                  </>
                ),
              },
            ],
          },
          {
            id: "block12-lesson4",
            label: `Урок 4. Як "дожимати" заявки в рекламі та переводити на продаж`,
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2012%2F%D0%A7%D0%95%D0%9A_%D0%9B%D0%98%D0%A1%D0%A2_%D0%9F%D0%9E_%D0%9F%D0%A0%D0%9E%D0%94%D0%90%D0%96%D0%A3_%D0%A3_%D0%9F%D0%9E%D0%92%D0%86%D0%94%D0%9E%D0%9C%D0%9B%D0%95%D0%9D%D0%9D%D0%AF%D0%A5.pdf?alt=media&token=e027965d-4af3-43c1-ad67-cc0bc0d64121",
                name: "Чек лист по продажу у повідомленнях",
              },
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2012%2F%D0%A7%D0%B5%D0%BA-%D0%BB%D0%B8%D1%81%D1%82%20%D0%BE%20%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%B6%D0%B0%D1%85%20%D0%B2%20%D0%BF%D0%B5%D1%80%D0%B5%D0%BF%D0%B8%D1%81%D0%BA%D0%B5%20.pdf?alt=media&token=030eea83-8be7-4e43-86b5-464d80fb1771",
                name: "Чек-лист о продажах в переписке",
              },
            ],
          },
          {
            id: "block12-lesson5",
            label: `Урок 5. Д/З`,
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            homework: true,
            info: [
              { content: "Створити скрипт продажів для ніші вашого клієнта" },
            ],
          },
        ],
      },
      {
        id: "block13",
        label: "Модуль 13. Блокування",
        description:
          "Зрозумієш, як покращувати результати та робити більше, а також рости в доході",
        disabled: false,
        children: [
          {
            id: "block13-lesson1",
            label: "Урок 1. Вступ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    Цей модуль допоможе уникнути блокувань або ж зменшити їх
                    можливість в роботі
                  </>
                ),
              },
            ],
          },
          {
            id: "block13-lesson2",
            label: "Урок 2. Все, що треба знати про блокування в ФБ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2013%2F%D0%B2%D1%81%D0%B5%20%D1%89%D0%BE%20%D1%82%D1%80%D0%B5%D0%B1%D0%B0%20%D0%B7%D0%BD%D0%B0%D1%82%D0%B8%20%D0%BF%D1%80%D0%BE%20%D0%B1%D0%BB%D0%BE%D0%BA%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D1%84%D0%B1.MP4?alt=media&token=d3d8213a-0c77-479f-a946-e59c111d1c39",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2013%2F%D0%92%D1%81%D0%B5%2C_%D1%89%D0%BE_%D1%82%D1%80%D0%B5%D0%B1%D0%B0_%D0%B7%D0%BD%D0%B0%D1%82%D0%B8_%D0%BF%D1%80%D0%BE_%D0%B1%D0%BB%D0%BE%D0%BA%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F_%D0%A4%D0%91_3.pdf?alt=media&token=bf6677c3-d034-44a8-964c-1aff7f61e96f",
                name: "Все що треба знати про блокування в ФБ",
              },
            ],
          },
          {
            id: "block13-lesson3",
            label: "Урок 3. Корисні посилання",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <div>
                      <strong>Посилання для розблокування:</strong>
                    </div>
                    <br />
                    <div> Питання з оплатами:</div>
                    <a href="https://www.facebook.com/help/contact/649167531904667">
                      https://www.facebook.com/help/contact/649167531904667
                    </a>
                    <br />
                    <a href="https://www.facebook.com/help/contact/?id=161710477317189">
                      https://www.facebook.com/help/contact/?id=161710477317189
                    </a>
                    <br />
                    <br />
                    <div>
                      Повідомити про проблему зі входом в аккаунт:
                      <br />
                      <a href="https://www.facebook.com/help/contact/357439354283890">
                        https://www.facebook.com/help/contact/357439354283890
                      </a>
                    </div>
                    <br />
                    <div>
                      Мій особистий профіль був вимкнутий:
                      <br />
                      <a href="https://www.facebook.com/help/contact/logoutid=260749603972907">
                        https://www.facebook.com/help/contact/logoutid=260749603972907
                      </a>
                    </div>
                    <br />

                    <div>
                      Заява на заблокований аккаунт - запит на підтвердження
                      особистості:
                      <br />
                      <a href="https://www.facebook.com/help/contact/logoutid=183000765122339">
                        https://www.facebook.com/help/contact/logoutid=183000765122339
                      </a>
                    </div>
                    <br />
                    <div>
                      Порушення правил:
                      <br />
                      <a href="https://www.facebook.com/help/contact/logoutid=317389574998690">
                        https://www.facebook.com/help/contact/logoutid=317389574998690
                      </a>
                    </div>
                    <br />
                    <div>
                      <strong>Підтримка Facebook</strong>
                      <br />
                      <br />
                      Чат з підтримкою фб (може бути не у всіх):
                      <br />
                      <a href="https://www.facebook.com/business/form/chat">
                        https://www.facebook.com/business/form/chat
                      </a>
                      <br />
                      <a href="https://www.facebook.com/business/help/support">
                        https://www.facebook.com/business/help/support
                      </a>
                    </div>
                    <br />
                    <div>
                      Якщо немає кнопки чату з підтримкою фб:
                      <br />
                      <a href="https://www.facebook.com/messages/t/facebookadsupport">
                        https://www.facebook.com/messages/t/facebookadsupport
                      </a>
                    </div>
                    <br />
                    <div>
                      Чат підтримки через офіційну сторінку:
                      <br />
                      <a href=" https://www.facebook.com/facebookadsupport">
                        https://www.facebook.com/facebookadsupport
                      </a>
                    </div>
                    <br />
                    <div>
                      Повідомлення від підтримки:
                      <br />
                      <a href="https://www.facebook.com/support/">
                        https://www.facebook.com/support/
                      </a>
                    </div>
                    <br />
                    <div>
                      <strong>Посилання для розблокування:</strong>
                    </div>
                    <br />
                    <div>Розблокування рекламного кабінету:</div>
                    <a href="https://www.facebook.com/help/contact/2026068680760273">
                      https://www.facebook.com/help/contact/2026068680760273
                    </a>
                    <br />
                    <br />
                    <div>Зняття обмежень аккаунта на розміщення реклами:</div>
                    <a href="https://www.facebook.com/help/contact/273898596750902">
                      https://www.facebook.com/help/contact/273898596750902
                    </a>
                    <br />
                    <br />
                    <div>
                      Зняття обмежень особистого профілю на використання
                      рекламних дій:
                    </div>
                    <a href="https://www.facebook.com/help/contact/956571484692817">
                      https://www.facebook.com/help/contact/956571484692817
                    </a>
                    <br />
                    <br />
                    <div>Розблокування бізнес менеджера:</div>
                    <a href="https://business.facebook.com/help/contact/2166173276743732">
                      https://business.facebook.com/help/contact/2166173276743732
                    </a>
                    <br />
                    <br />
                    <div>
                      Розблокування рекламного аккаунту через відхилення
                      платежу:
                    </div>
                    <a href="https://www.facebook.com/help/contact/531795380173090">
                      https://www.facebook.com/help/contact/531795380173090
                    </a>
                  </>
                ),
              },
            ],
          },
          {
            id: "block13-lesson4",
            label: "Урок 4. Як боротися з блокуваннями ФБ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    <div>
                      <strong>Прогрів рекламного акаунту на Facebook.</strong>
                    </div>
                    <p>
                      Це процес поступового збільшення активності та взаємодії
                      акаунту перед тим, як розпочати інтенсивну рекламну
                      кампанію. Навіть граючи в ігри (а це найкраще прогріває
                      сторінку), ви можете допомагати вашому клієнту. Вибір
                      провести прогрів акаунту має свої за й проти.
                    </p>

                    <p>
                      <strong>Плюси:</strong>
                    </p>

                    <ul>
                      <li>
                        <strong>Зниження ризику бану:</strong> Прогрів дозволяє
                        підготувати акаунт на платформі, виконуючи невеликі та
                        поступові дії, що зменшує ризик бану через раптовий
                        підвищений трафік або підозрілу активність.
                      </li>

                      <li>
                        <strong>Підвищення рівня довіри:</strong> Facebook не
                        любить "мертві" аккаунти. А з підвищенням активності
                        акаунту, Facebook бачить вас як живого та активного
                        користувача, що також може сприяти впливу на показники
                        якості та зниженню ціни реклами.
                      </li>

                      <li>
                        <strong>Відсів проблем:</strong> Прогрів акаунту
                        допомагає виявити можливі технічні проблеми з платформою
                        чи рекламою, дозволяючи їх виправити перед запуском
                        важливої кампанії.
                      </li>
                    </ul>

                    <div>
                      <strong>Мінуси:</strong>
                    </div>
                    <ul>
                      <li>
                        <strong>Часові витрати:</strong> Прогрів вимагає
                        додаткового часу та зусиль, оскільки потребує постійної
                        активності та взаємодії на платформі до запуску
                        рекламної кампанії. (прогрів в ідеалі робити 7 днів)
                      </li>

                      <li>
                        <strong>Додаткові витрати коштів:</strong> Прогрів також
                        може здійснюватися з мінімальними витратами на запуск
                        невеличких кампаній (наприклад, на перегляди, реакції чи
                        кліки).
                      </li>

                      <li>
                        <strong>
                          Прогрів не допоможе вже забаненому акаунту:
                        </strong>{" "}
                        Прогрів є дієвим інструментом, проте він спрямований на
                        те, щоб уникнути бану, а не витягнути вас звідти.
                      </li>
                    </ul>

                    <div>
                      <strong>
                        2. Звернення в підтримку Facebook на розбан або
                        самостійна подача апеляції на розбан рекламного акаунту.
                      </strong>
                    </div>
                    <p>
                      Тут є два варіанти: подача апеляції через форму, або ж
                      документів в account quality. Який саме варіант – залежить
                      від вашого акаунта, бо не на всіх є конпка саппорта
                      (підтримки). Обидва варіанти не дають гарантованих шансів,
                      тому тут залежить від того, наскільки прокачана вдача.
                    </p>

                    <p>
                      <strong>Плюси:</strong>
                    </p>
                    <ul>
                      <li>
                        <strong>Шанс на відновлення акаунту:</strong> Звернення
                        в підтримку дозволяє вам звернутися безпосередньо до
                        представників Facebook з проханням переглянути вашу
                        ситуацію та відновити рекламний акаунт.
                      </li>

                      <li>
                        <strong>Розуміння причин блокування:</strong> Завдяки
                        зверненню ви, можливо, навіть дізнатися приблизну
                        причину блокування вашого акаунту, що допоможе уникнути
                        подібних проблем у майбутньому.
                      </li>

                      <li>
                        <strong>
                          Офіційне звернення і експертна підтримка:
                        </strong>{" "}
                        Подача звернення у підтримку є офіційним способом
                        звернутися до Facebook, що може позитивно позначитися на
                        вашій ситуації та взаємодії з платформою. Представники
                        підтримки Facebook можуть надати вам професійні поради
                        та рекомендації щодо того, як уникнути проблем з
                        аккаунтом у майбутньому.
                      </li>
                    </ul>

                    <div>
                      <strong>Мінуси:</strong>
                    </div>
                    <ul>
                      <li>
                        <strong>Часові витрати:</strong> Звернення до підтримки
                        може зайняти довгий час, оскільки вам доведеться чекати
                        на відповідь та взаємодіяти з представниками компанії. А
                        знаючи як “оперативно” працюють представники Facebook у
                        вирішенні питань, можна чекати дуже довго. Це може
                        призвести до затримки в запуску рекламних кампаній або
                        вплинути на ваші бізнес-плани (що в свою чергу тягне за
                        собою і втрату коштів).
                      </li>

                      <li>
                        <strong>Відсутність гарантії:</strong> На жаль, немає
                        гарантій того, що ваш акаунт буде розблоковано після
                        звернення. Вирішення ситуації залежатиме від внутрішніх
                        політик та правил Facebook.
                      </li>
                    </ul>

                    <div>
                      <strong>3. Купівля фармлених акаунтів.</strong>
                    </div>
                    <p>
                      Мова йде про штучно створені акаунти, які можна придбати
                      задля використання їх у рекламних кампаніях. Фармлені
                      акаунти можуть бути створені автоматично за допомогою
                      ботів або людей з метою запуску реклами (часто на “сірі”
                      ніші).
                    </p>

                    <p>
                      <strong>Плюси:</strong>
                    </p>
                    <ul>
                      <li>
                        <strong>Швидкий доступ:</strong> Не потрібно витрачати
                        час на органічний розвиток (прогрів) акаунтів. Купівля
                        фармлених забезпечує швидкий доступ до багатьох акаунтів
                        одразу.
                      </li>

                      <li>
                        <strong>Велика кількість активних акаунтів:</strong>{" "}
                        Купівля фармлених акаунтів дає змогу отримати доступ до
                        великої кількості активних облікових записів, що може
                        частково вирішити питання банів і продовжувати роботу на
                        іншому аккаунті, поки попередній в бані.
                      </li>

                      <li>
                        <strong>Відносно низька вартість:</strong> Фармлені
                        акаунти зазвичай продаються за низькі ціни порівняно з
                        офіційними акаунтами, що може збільшити привабливість їх
                        для деяких таргетологів або власників бізнесів.
                      </li>
                    </ul>

                    <div>
                      <strong>Мінуси:</strong>
                    </div>
                    <ul>
                      <li>
                        <strong>Ризики порушення правил:</strong> Купівля та
                        використання фармлених акаунтів є порушенням правил
                        Facebook. Якщо платформа виявить, що ви використовуєте
                        фармлені акаунти, то вас заблокують.
                      </li>

                      <li>
                        <strong>Низька якість:</strong> Фармлені акаунти можуть
                        містити недійсні або неактивні облікові записи, що
                        знижує якість ваших рекламних кампаній.
                      </li>

                      <li>
                        <strong>Ризик безпеки і репутації:</strong> Використання
                        фармлених акаунтів може підірвати довіру клієнтів і
                        пошкодити репутацію вашої компанії або бренду.
                      </li>

                      <li>
                        <strong>
                          Рішення не на довгострокову перспективу:
                        </strong>{" "}
                        Через “сірість” цього методу і ризики його використання,
                        розглядати таке рішення на довгострок важко. Зазвичай
                        такі акаунти – це витратний матеріал.
                      </li>
                    </ul>

                    <div>
                      <strong>4. Агентські акаунти.</strong>
                    </div>
                    <p>
                      Якщо ви не хочете працювати з розбанами, постійною
                      купівлею аків, прогрівом і тд, можеш обрати цей варіант.
                      Агентські акаунти – це трастові рекламні кабінети,
                      створені з підтримки Meta, тобто в системі Meta
                      реєструється маркетингова компанія, FB апрувить їх дані
                      (перевіряє документи бізнесу), і створює їм рекламні
                      кабінети. За рахунок підтвердженої бізнес діяльності фб
                      розглядає компанію як партнерів, й тому має більшу довіру
                      до компанії.
                    </p>

                    <strong>Плюси:</strong>
                    <ul>
                      <li>
                        <strong>
                          Ваш акаунт практично ніколи не отримує бани:
                        </strong>{" "}
                        Facebook його розцінює як акаунт з високим рівнем
                        довіри.
                      </li>

                      <li>
                        <strong>У вас безлімітні денні бюджети:</strong> та
                        швидкий апрув кампаній від Facebook.
                      </li>

                      <li>
                        <strong>Нижчий CPM та якісніша аудиторія:</strong>{" "}
                        Відповідно, менші витрати і кращі результати.
                      </li>

                      <li>
                        <strong>Вирішенням проблем займається менеджер:</strong>{" "}
                        Якщо ви все-таки отримали бан, то вам не потрібно шукати
                        способи для розбану самостійно. Цим займеться менеджер
                        агентського акаунту.
                      </li>
                    </ul>

                    <strong>Мінуси:</strong>
                    <ul>
                      <li>
                        <strong>Обмеження по використанню акаунтів:</strong>{" "}
                        Один агентський акаунт – один проект, тому під кожен
                        новий проєкт потрібен окремий агентський акаунт.
                      </li>

                      <li>
                        <strong>
                          Для входу на агентські аки все має бути готово:
                        </strong>{" "}
                        (вся воронка, по якій йтиме клієнт). Має бути готовий
                        куплений домен, сайт, створені та повністю заповненні
                        ФП, мінімум від 3-х постів.
                      </li>

                      <li>
                        <strong>
                          Не зможете відкрити самостійно, якщо у вас рекламний
                          бюджет до 30 000$:
                        </strong>{" "}
                        Однак, якщо у вас менший бюджет ви можете працювати
                        через надійних партнерів.
                      </li>
                    </ul>

                    <p>
                      <strong>А що робити з денними лімітами?</strong>
                    </p>

                    <p>
                      Якщо навіть у вас достатньо коштів, і продукт, який ви
                      протестували, працює, ви просто не зможете його
                      масштабувати, якщо у вашого акаунту є обмеження,
                      наприклад, в 50$ чи навіть 100$ на день.
                    </p>
                    <br />
                    <strong>Рішення проблеми лімітів:</strong>
                    <ul>
                      <li>
                        <strong>
                          Прогрів акаунту, лайтові рекламні кампанії:
                        </strong>{" "}
                        Витрачати побільше коштів на рекламу, аби списувалися
                        білінги. Якщо мова йде про новий чи відносно свіжий
                        акаунт, де ще не було довгої історії оплачених рахунків
                        за рекламу, хорошою ідеєю буде поступове збільшення
                        бюджетів, запуск кампаній не одразу на конверсію (а,
                        наприклад, на взаємодію з постом).
                      </li>

                      <li>
                        <strong>
                          Звернення в підтримку для збільшення лімітів:
                        </strong>{" "}
                        Як і у випадку з банами. Якщо ви впевнені в якості свого
                        акаунта, маєте позитивну історію, а також бажання
                        витратити грошенят на рекламу, можете звертатися до
                        підтримки і аргументовано просити про збільшення порогу
                        денних витрат.
                      </li>

                      <li>
                        <strong>Купівля високолімітних акаунтів:</strong>{" "}
                        Високолімітні акаунти дозволяють розширити потенціал
                        рекламних витрат, що може бути корисним для
                        масштабування ваших рекламних кампаній. Це готове
                        рішення під ключ, яке потребує лише фінансових витрат.
                        Високолімітні акаунти можуть коштувати значно більше,
                        ніж стандартні акаунти. Ну і завжди існує ризик купівлі
                        акаунтів від ненадійних продавців, які можуть продавати
                        фейкові або неправомірно здобуті облікові записи. Ну і
                        навіть ці акаунти не дають гарантії від банів.
                      </li>

                      <li>
                        <strong>Агентські акаунти:</strong> Найнадійніше
                        рішення, адже як ми і описували вище, цей тип акаунтів
                        переважно не має обмежень по витратах, і користується
                        максимальною довірою Facebook. Це вже праця в довгу.
                      </li>
                    </ul>
                  </>
                ),
              },
            ],
          },
        ],
      },
      {
        id: "block14",
        label: "Модуль 14. Масштабування",
        description:
          "Цей модуль допоможе уникати блокування або ж зменшити їх можливість в роботі",
        disabled: false,
        children: [
          {
            id: "block14-lesson1",
            label: "Урок 1. Вступ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content: (
                  <>
                    Зрозумієш, як покращувати результати та робити більше, а
                    також рости в доході
                  </>
                ),
              },
            ],
          },
          {
            id: "block14-lesson2",
            label: "Урок 2. Методи масштабування проекту ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2014%2F%D0%BC%D0%B0%D1%81%D1%88%D1%82%D0%B0%D0%B1%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D1%83.MP4?alt=media&token=4860274c-7846-4c37-ae4e-5f63792e5360",
              },
            ],
            info: [
              {
                content: (
                  <>
                    Масштабування - це збільшення числа цільових дій (заявки,
                    кліки, конверсії і т.д.) при збереженні їхньої вартості.
                    Часто результативність знижується через "вигорання
                    аудиторії". Але помилково думати, що реклама вже була
                    показана всім потенційним покупцям.
                  </>
                ),
              },
            ],
          },
          {
            id: "block14-lesson3",
            label:
              "Урок 3. Які послуги ви можете ще надавати, щоб збільшити свій дохід",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2014%2F%D0%BC%D0%B0%D1%81%D1%88%D1%82%D0%B0%D0%B1%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82%D0%BE%D0%BB%D0%BE%D0%B3%D0%B0.MP4?alt=media&token=5ed1ab75-bde8-45b7-8469-e4066f066d0f",
              },
            ],
          },
          {
            id: "block14-lesson4",
            label:
              "Урок 4. Як підняти чек на послуги діючим та майбутнім клієнтам",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            audio: [
              {
                audio:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2014%2F1702418972.mp3?alt=media&token=f2cb3ef2-e0fb-4ab2-83d4-ffc44ba7e2f5",
              },
            ],
          },
        ],
      },
      {
        id: "block15",
        label: "Модуль 15. Бьюті ніша",
        description:
          "Зможеш працювати з різними експертами та давати їм круті результати",
        disabled: false,
        children: [
          {
            id: "block15-lesson1",
            label: "Урок 1. Вступ",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            info: [
              {
                content:
                  "Завдяки цьому уроку ти перестанеш боятись брати бьюті нішу в роботу та почнеш приносити записи клієнту на місяць вперед",
              },
            ],
          },
          {
            id: "block15-lesson2",
            label: "Урок 2. Бьюті ніша",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: false,
            video: [
              {
                video:
                  "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2015%2F%D0%B1%D1%8C%D1%8E%D1%82%D0%B8.MP4?alt=media&token=8aea1df6-ad2d-4d90-8594-bde23d2dab0b",
              },
            ],
            file: [
              {
                file: "https://firebasestorage.googleapis.com/v0/b/kovalevskayaplatform-39007.appspot.com/o/%D0%9C%D0%B0%D1%82%D0%B5%D1%80%D1%96%D0%B0%D0%BB%D0%B8%20%D0%BA%D1%83%D1%80%D1%81%D1%83%2FModule%2015%2F%D0%AF%D0%BA%20%D0%BF%D1%80%D0%B0%D1%86%D1%8E%D0%B2%D0%B0%D1%82%D0%B8%20%D0%B7%20%D0%B1%CA%BC%D1%8E%D1%82%D1%96%20%D0%BD%D1%96%D1%88%D0%B5%D1%8E-2.pdf?alt=media&token=e9712101-0e61-4939-9138-133220ca3a40",
                name: "Як працювати з бьюті нішею",
              },
            ],
          },
          // {
          //   id: "block15-lesson3",
          //   label: "Урок 3",
          //   description: "- Lorem ipsum dolor sit amet, consectetur adip",
          //   disabled: true,
          // },
          // {
          //   id: "block15-lesson4",
          //   label: "Урок 4",
          //   description: "- Lorem ipsum dolor sit amet, consectetur adip",
          //   disabled: true,
          // },
        ],
      },
      {
        id: "block16",
        label: "Модуль 16. Бьюті ніша",
        description: "Перестанеш боятись брати б’юті нішу в роботу",
        disabled: true,
        children: [
          {
            id: "block16-lesson1",
            label: "Урок 1",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: true,
          },
          {
            id: "block16-lesson2",
            label: "Урок 2",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: true,
          },
          {
            id: "block16-lesson3",
            label: "Урок 3",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: true,
          },
          {
            id: "block16-lesson4",
            label: "Урок 4",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: true,
          },
        ],
      },
      {
        id: "block17",
        label: "Модуль 17. E-COM",
        description:
          "Ти позбудешся страху працювати з e-com проектами та навчишся давати результати",
        disabled: true,
        children: [
          {
            id: "block17-lesson1",
            label: "Урок 1",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: true,
          },
          {
            id: "block17-lesson2",
            label: "Урок 2",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: true,
          },
          {
            id: "block17-lesson3",
            label: "Урок 3",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: true,
          },
          {
            id: "block17-lesson4",
            label: "Урок 4",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: true,
          },
        ],
      },
      {
        id: "block18",
        label: "Модуль 18. Створення сильного особистого бренду",
        description:
          "Якщо ти не знала(-в) з чого почати в інстаграм - то цей модуль дасть усі знання та впевненність, щоб створити сильний особистий бренд",
        disabled: true,
        children: [
          {
            id: "block18-lesson1",
            label: "Урок 1",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: true,
          },
          {
            id: "block18-lesson2",
            label: "Урок 2",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: true,
          },
          {
            id: "block18-lesson3",
            label: "Урок 3",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: true,
          },
          {
            id: "block18-lesson4",
            label: "Урок 4",
            description: "- Lorem ipsum dolor sit amet, consectetur adip",
            disabled: true,
          },
        ],
      },
    ],
  },
];
