import React from "react";
import SignUp from "../../components/Auth/SignUp";

const SignUpPage = () => {
  return (
    <>
      <SignUp />
    </>
  );
};

export default SignUpPage;
